import React, { useEffect, useState } from "react";
import "./MainJoinReqs.scss";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import Navbar from "../../components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllJoinReqs } from "../../store/joinreqs";
import PaginationList from "../../components/Pagination/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoDataComponent from "../../uti/NoDataComponent";
function MainJoinReqs() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [joinReqs, setJoinReqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllJoinReqs({ lang: i18n.language, page: 1 }));
  }, []);

  let { data, loading, errorAll } = useSelector((state) => state.joinReqs);

  useEffect(() => {
    const joinReqDataArray = data?.data?.data;
    if (joinReqDataArray) {
      setJoinReqs(joinReqDataArray);
      setTotalPages(joinReqDataArray.total);
      setCurrentPage(joinReqDataArray.current_page);
    }
  }, [data]);

  const cellStyle = {
    textAlign: i18n.language === "ar" ? "right" : "left",
  };
  const tableCells = ["id", "name", "phone", "job"];
  const Row = ({ row }) => (
    <TableRow>
      {Object.entries(row).map(
        ([key, value], index) =>
          key !== "updated_at" &&
          key !== "created_at" && ( // Check if the key is not 'updated_at' or 'created_at'
            <TableCell key={index}>{value}</TableCell>
          )
      )}
    </TableRow>
  );

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    dispatch(getAllJoinReqs({ lang: i18n.language, page: newPage }));
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Join Requests")}</h3>
        </div>
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <>
              {/* <Datatable userColumns={userColumns} userRows={joinReqs} /> */}
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow  style={{ width: `${100 / tableCells.length}%` }}>
                      {tableCells.map((label, index) => (
                        <TableCell key={index} style={cellStyle}>
                          {t(label)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {joinReqs?.data?.length === 0 && (
                      <NoDataComponent
                        colSpan={tableCells.length}
                        text={t("There is no Join-requests to show")}
                      />
                    )}
                    {joinReqs?.data?.map((row) => (
                      <Row key={row.name} row={row} />
                    ))}
                  </TableBody>
                </Table>
                <PaginationList
                  totalPages={joinReqs?.data?.last_page}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MainJoinReqs;
