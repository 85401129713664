import React from "react";
import "./ModalDelete.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import PopUpDeleteRegions from "../../pages/Folder-Regions/PopUpDeleteRegions/PopUpDeleteRegions";
import { useTranslation } from "react-i18next";
import PopUpDeleteSellers from "../../pages/Folder-Sellers/PopUpDeleteSellers/PopUpDeleteSellers";
import PopUpDeleteSubCategory from "../../pages/Folder-SubCategory/PopUpDeleteSubCategory/PopUpDeleteSubCategory";
import PopUpDeleteVariant from "../../pages/Folder-Variant/PopUpDeleteVariant/PopUpDeleteVariant";
import PopUpDeleteProduct from "../../pages/Folder-Product/PopUpDeleteProduct/PopUpDeleteProduct";
import DeletePopUpRole from "../../pages/Folder-role/DeletePopUpRole/DeletePopUpRole";
import PopUpDeleteUser from "../../pages/Folder-Users/PopUpDeleteUser/PopUpDeleteUser";
import PopUpDeleteCaptin from "../../pages/Folder-Captins/PopUpDeleteCaptin/PopUpDeleteCaptin";
import DeletePopUpNotifi from "../../pages/FolderNotification/DeletePopUpNotifi/DeletePopUpNotifi";
import PopUpDeleteState from "../../pages/Folder-State/PopUpDeleteState/PopUpDeleteState";
import PopUpDeleteCategory from "../../pages/Folder-Category/PopUpDeleteCategory/PopUpDeleteCategory";
import PopUpDeleteCommission from "../../pages/Folder-Commission/PopUpDeleteCommission/PopUpDeleteCommission";
import DeletePopUpMainDeliveryMethods from "../../pages/Folder-DeliveryMethod/DeletePopUpMainDeliveryMethods/DeletePopUpMainDeliveryMethods";
import DeletePopUpDeliveyAttrebute from "../../pages/Folder-DeliveryAttrebute/DeletePopUpDeliveyAttrebute/DeletePopUpDeliveyAttrebute";
import DeletePopUpBanners from "../../pages/Folder-Banners/DeletePopUpBanners/DeletePopUpBanners";
import PopUpDeleteGift from "../../pages/Folder-Gift/PopUpDeleteGift/PopUpDeleteGift";
import DeletePopUpCoupons from "../../pages/Folder-Coupons/DeletePopUpCoupons/DeletePopUpCoupons";
import PopUpDeleteHome from "../../pages/AdminHome/PopUpDeleteHome/PopUpDeleteHome";

function ModalDelete({ params: data, type, selectedRowIds, filter }) {
  console.log("type", type);
  const [t, i18n] = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div onClick={handleOpen} className="d-flex gap-2 align-items-center">
        {<DeleteIcon />}
        {type === "all" ? t("Delete Selected") : ""}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <div className="head">
                <div className="title-head">
                  {type === "all"
                    ? `${t("Delete Selected")}`
                    : `${t("Delete")}`}
                  <CloseIcon className="icon" onClick={handleClose} />
                </div>
              </div>
            </Typography>

            {filter === "regions" ? (
              <PopUpDeleteRegions
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Sellers" ? (
              <PopUpDeleteSellers
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Categories" ? (
              <PopUpDeleteCategory
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "SubCategories" ? (
              <PopUpDeleteSubCategory
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Variant" ? (
              <PopUpDeleteVariant
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Product" ? (
              <PopUpDeleteProduct
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Role" ? (
              <DeletePopUpRole
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "users" ? (
              <PopUpDeleteUser
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Captins" ? (
              <PopUpDeleteCaptin
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Notification" ? (
              <DeletePopUpNotifi
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "states" ? (
              <PopUpDeleteState
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Commission" ? (
              <PopUpDeleteCommission
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "DeliveryMethod" ? (
              <DeletePopUpMainDeliveryMethods
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "deliveryattrebute" ? (
              <DeletePopUpDeliveyAttrebute
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Banners" ? (
              <DeletePopUpBanners
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "Coupon" ? (
              <DeletePopUpCoupons
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "gifts" ? (
              <PopUpDeleteGift
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : filter === "homePage" ? (
              <PopUpDeleteHome
                data={data}
                handleClose={handleClose}
                type={type}
                selectedRowIds={selectedRowIds}
              />
            ) : (
              ""
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalDelete;
