import React, { useEffect, useState } from "react";
import "./MainDeliveryMethods.scss";
import Datatable from "../../../components/datatable/Datatable";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import Navbar from "../../../components/Navbar/Navbar";
// import Actions from "../../../components/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllComplaints } from "../../../store/complaints";

function MainComplaints() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [complaints, setComplaints] = useState([]);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllComplaints({ lang: i18n.language }));
  }, []);

  let { data, loading, errorAll } = useSelector((state) => state.complaints);

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const formattedComplaints = data.data.map(complaint => ({
        ...complaint,
        created_at: complaint.created_at.split('T')[0], // Corrected split method
        updated_at: complaint.updated_at.split('T')[0] // Corrected split method
      }));
      setComplaints(formattedComplaints);
    }
  }, [data]);

  console.log("data", data?.data);

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 200 },
    {
      field: "created_at",
      headerName: t("Date"),
      width: 350,
    },
    {
      field: "email",
      headerName: t("Email"),
      width: 200,
    },
    {
      field: "notes",
      headerName: t("Complaint"),
      width: 200,
    },

    // {
    //   field: "Action",
    //   headerName: t("Action"),
    //   sortable: false,
    //   filter: false,
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <div className="actions-box">
    //         <Actions filter="DeliveryMethod" params={params} />
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Complaints")}</h3>
          {/* <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={DeliveryMethod}
                filter="DeliveryMethod"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="DeliveryMethod"
                params={DeliveryMethod}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div> */}
        </div>
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable userColumns={userColumns} userRows={complaints} />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainComplaints;
