import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  assignCouponToUser,
  createCoupon,
  getAllCoupons,
  getAllCouponsTypes,
  resetAllCouponsAction,
  resetDataCreate,
  resetDataUpdate,
  setSingleData,
  updateCoupon,
} from "../../../store/coupons";
import { getAllusers } from "../../../store/userslice";
import Select from "react-select";
function PopUpAssignCoupon({ handleClose, type, data }) {
  console.log("data", data);
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let [selectedUser, setSelectedUser] = useState("");
  let [selectedCoupon, setSelectedCoupon] = useState("");

  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();
  let {
    data: coupons,
    loadingBTN,
    types,
    error,
    dataUpdate,
    dataCreate,
    typesLoading,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.Coupons);

  let {
    data: users,
    loading: usersLoading,
    usersError,
  } = useSelector((state) => state.users);
  console.log("coupons", coupons);

  useEffect(() => {
    dispatch(getAllCouponsTypes({ lang: i18n.language }));
    dispatch(getAllusers({ lang: i18n.language }));
  }, [type]);

  let usersOptions =
    users &&
    users.length > 0 &&
    users?.map((user) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  let couponsOptions =
    coupons &&
    coupons.length > 0 &&
    coupons?.map((coupon) => {
      return {
        label: `${coupon.coupon_type.name}(${coupon.description})`,
        value: coupon?.id,
      };
    });

  let onsubmitfn = async (e) => {
    e.preventDefault();

    dispatch(
      assignCouponToUser({
        data: {
          coupon_id: selectedCoupon.value,
          user_id: selectedUser.value,
        },
        lang: i18n.language,
      })
    ).then((action) => {
      if (assignCouponToUser.fulfilled.match(action)) {
        handleClose();
      }
    });
  };
  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" ,overflow:"visible"}}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {t("Assign Coupon To User")}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("User")}: <sup className="redstar">*</sup>
                </label>
                <Select
                  options={usersOptions}
                  placeholder={t("Choose User")}
                  value={selectedUser}
                  onChange={(selected) => {
                    setSelectedUser(selected);
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Coupon")}: <sup className="redstar">*</sup>
                </label>
                <Select
                  options={couponsOptions}
                  placeholder={t("Choose Coupon")}
                  value={selectedCoupon}
                  onChange={(selected) => {
                    setSelectedCoupon(selected);
                  }}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {t("Assign")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpAssignCoupon;
