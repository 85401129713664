import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar/Navbar";
import "./Reports.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllHomeData } from "../../store/home";
import { useTranslation } from "react-i18next";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import ModalMulti from "../../components/ModalMulti/ModalMulti";
import CircleChart from "./circleChart/CircleChart";
import { Grid } from "@material-ui/core";
import BarsChart from "./barChart/BarsChart";
import TotalCard from "./Card/Card";
import {
  getAllCategoriesReport,
  getAllCoupounsNumber,
  getAllDriversReport,
  getAllEarnings,
  getAllNumbers,
  getAllOrdersStatus,
  getAllUsedPoint,
} from "../../store/statistics";
import DriversReport from "./DriversReport/DriversReport";
import Earnings from "./Earnings/Earnings";
import UsedPoint from "./usedPoint/UsedPoint";
// import BarsChart from "./barChart/BarsChart";
// import OrdersReport from "./OrdersReport/OrderReport";

function Reports() {
  const [t, i18n] = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState({
    label: "January",
    value: 1,
  });

  const [date, setDate] = useState({
    from_date: "2024-03-6",
    to_date: "2024-03-6",
  });

  const [date3, setDate3] = useState({
    from_date: "2024-06-26",
    to_date: "2024-06-26",
  });
  const [data2, setData2] = useState({
    year: "2024",
  });

  const {
    loading,
    loadingCategories,
    error,
    data,
    ordersStatus,
    categories,
    couponsNmbers,
    drivers,
    earnings,
    usedpoint,
    loadingeEarnings,
    loadingeUsedPoint,
  } = useSelector((state) => state.statistics);

  const arrayOfObjects = Object.entries(data).map(([title, num]) => ({
    title,
    num,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllNumbers({ lang: i18n.language }));
    dispatch(getAllOrdersStatus({ lang: i18n.language }));
    dispatch(getAllCoupounsNumber({ lang: i18n.language }));
    dispatch(
      getAllCategoriesReport({
        lang: i18n.language,
        from_date: date.from_date,
        to_date: date.to_date,
      })
    );
    dispatch(getAllDriversReport({ lang: i18n.language,  }));
    dispatch(getAllEarnings({ lang: i18n.language, from_date: date3.from_date,
      to_date: date3.to_date, }));
    dispatch(getAllUsedPoint({ lang: i18n.language, year: data2.year }));
  }, []);

  const Reports = (
    <div>
      <Grid container spacing={3}>
        {arrayOfObjects?.map((card) => (
          <Grid item xs={12} sm={4}>
            <TotalCard cardInfo={card} t={t} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <p className="my-2 d-flex">{t("Statistics_for_orders")}</p>
          <CircleChart info={ordersStatus} type="orders" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className="my-2 d-flex">{t("Statistics_for_coupons")}</p>
          <CircleChart info={couponsNmbers} type="coupons" />
        </Grid>
      </Grid>

      <p className="my-2 d-flex">{t("Statistics_for_drivers")}</p>
      <DriversReport info={drivers} />
      <p className="my-2 d-flex">{t("Statistics_for_categories")}</p>

      <BarsChart
        info={categories}
        // setSelectedMonth={setSelectedMonth}
        setDate={setDate}
        date={date}
        dispatch={dispatch}
        getAllCategoriesReport={getAllCategoriesReport}
        i18n={i18n}
        loadingCategories={loadingCategories}
      />
      <p className="my-2 d-flex">{t("Statistics_for_Earnings")}</p>

      <Earnings
        info={earnings}
        // setSelectedMonth={setSelectedMonth}
        setDate3={setDate3}
        date3={date3}  
        dispatch={dispatch}
        getAllEarnings={getAllEarnings}
        i18n={i18n}
        loadingeEarnings={loadingeEarnings}
      />
      <p className="my-2 d-flex">{t("Statistics_for_used")}</p>

      <UsedPoint
        info={usedpoint}
        // setSelectedMonth={setSelectedMonth}
        setData2={setData2}
        data2={data2}
        dispatch={dispatch}
        getAllEarnings={getAllUsedPoint}
        i18n={i18n}
        loadingeUsedPoint={loadingeUsedPoint}
      />
    </div>
  );

  let content;
  switch (true) {
    case data:
      content = Reports;
      break;
    case loading:
      content = (
        <div className="loading">
          <CircularIndeterminate />
        </div>
      );
      break;
    case error:
      content = <ErrorCompo />;
      break;

    default:
      content = Reports;
  }

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="home">
        <div className="d-flex justify-content-between align-items-center">
          <h3>{t("Reports")}</h3>
        </div>
        {content}
      </div>
    </div>
  );
}

export default Reports;
