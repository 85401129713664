import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { MdShoppingCart } from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import { FaUsers, FaTruck, FaBox, FaStore, FaCodepen, FaIdCard, FaVrCardboard } from "react-icons/fa";

const TotalCard = ({ cardInfo, totalOrders, t }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const incrementCounter = () => {
      const increment = cardInfo?.num / 100;
      if (counter < cardInfo.num) {
        setCounter((prevCounter) =>
          Math.min(prevCounter + increment, cardInfo?.num)
        );
      }
    };

    const timer = setInterval(incrementCounter, 10);

    return () => clearInterval(timer);
  }, [cardInfo]);

  // Icon mapping
  const iconMap = {
    orders_num: <MdShoppingCart size={48} className="mb-2" color="#007bff" />,
    products_num: (
      <RiProductHuntLine size={48} className="mb-2" color="#28a745" />
    ),
    users_num: <FaUsers size={48} className="mb-2" color="#6610f2" />,
    drivers_num: <FaTruck size={48} className="mb-2" color="#dc3545" />,
    categories_num: <FaBox size={48} className="mb-2" color="#ffc107" />,
    sellers_num: <FaStore size={48} className="mb-2" color="#17a2b8" />,
    purchased_coupons_num: <FaVrCardboard size={48} className="mb-2" color="#Df2205" />,
    compensation_coupons_num: <FaVrCardboard size={48} className="mb-2" color="#4CAF50" />,
    periodic_coupons_num: <FaVrCardboard size={48} className="mb-2" color="#F32167" />,
  };

  return (
    <Card className="text-center">
      <Card.Body className="d-flex justify-content-between align-items-center">
        <div>
          {iconMap[cardInfo?.title]}
          <Card.Title className="bold">{t(cardInfo?.title)}</Card.Title>
        </div>
        <div>
          <Card.Text style={{ fontSize: "20px", fontWeight: "bold" }}>
            {Math?.round(counter)}
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TotalCard;
