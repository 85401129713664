import React from 'react';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import nodatavector from "../assits/nodatavector.jpg";

const NoDataComponent = ({ colSpan, text}) => (
  <TableRow>
     <TableCell colSpan={colSpan} style={{ textAlign: "center" }}>
      <img src={nodatavector} alt="no data" />
      <Typography>{text}</Typography>
     </TableCell>
   </TableRow>
);

export default NoDataComponent;
