import React, { useEffect, useState } from "react";
import "./PopUpMainDeliveryMethods.scss";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createDeliveryMethod,
  getAllDeliveryMEthod,
  getSingleDeliveryMEthod,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataDeleveryMethod,
  updateDelveryMethod,
} from "../../../store/DeliveryMethod";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { Switch } from "@mui/material";

function PopUpMainDeliveryMethods({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.deliverymethod);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllDeliveryMEthod({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      dispatch(resetsingleDataDeleveryMethod(""));
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleDeliveryMEthod({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };
  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      dispatch(createDeliveryMethod({ data: inp, lang: i18n.language })).then(
        (action) => {
          if (createDeliveryMethod.fulfilled.match(action)) handleClose();
        }
      );
    }
    if (type === "edit") {
      let newdata = { ...inp, _method: "PUT" };
      dispatch(
        updateDelveryMethod({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      ).then((action) => {
        if (updateDelveryMethod.fulfilled.match(action)) handleClose();
      });
    }
  };

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Delivery Methods")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Delivery Methods")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
            <p className="d-flex justify-content-center btn btn-danger mx-auto " style={{width:"60%"}}>{t('Enter_Add_Lang')}</p>

              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Delivery Methods Name")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter  name"
                  defaultValue={type === "edit" ? singleData.name : ""}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Delivery Methods Time")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="time"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter  time"
                  defaultValue={type === "edit" ? singleData.time : ""}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Delivery Methods Price")}:{" "}
                  <sup className="redstar">*</sup>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="price"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter  price"
                  defaultValue={type === "edit" ? singleData.price : ""}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className="form-group">
                <p>
                  {t("Is schedule")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    singleData
                      ? singleData.is_schedule === false
                        ? false
                        : true
                      : false
                  }
                  
                  onChange={(event) => handleSwitchChange(event, "is_schedule")}
                />
              </div>
            </div>
            <div className="btn-lest mt-3">
              <button
                type="submit"
                className="btn btn-primary-rgba"
                disabled={loadingBTN}
              >
                <CheckCircleIcon color="#fff" />{" "}
                {loadingBTN
                  ? t("Loading ...")
                  : type === "edit"
                  ? t("Edit")
                  : t("Create")}
              </button>

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpMainDeliveryMethods;
