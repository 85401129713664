// TrackingMap.js
import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import marker from "../../assits/marker.svg"
const mapContainerStyle = {
  width: "100%",
  height: "550px",
};

const center = {
  lat: 33.5138,
  lng: 36.2765,
};


const TrackingMap = ({ driveres }) => {
  console.log("driveres", driveres);
  
  return (
    <LoadScript googleMapsApiKey="AIzaSyCUZlq_lAp7CeU_lPj9z8cZWYC2qE_uRZ4">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
      >
        {/* Generate markers for each driver */}
        {driveres?.map((driver, index) => (
          <Marker
            key={index}
            position={{
              lat: parseFloat(driver.lat),
              lng: parseFloat(driver.long),
            }}
            icon={{
              url: marker, 
              scaledSize: new window.google.maps.Size(70, 70), 
            }}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default TrackingMap;
