import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { useState } from "react";
import { getAllUsedPoint } from "../../../store/statistics";

export default function UsedPoint({
  info,
  i18n,
  dispatch,
  loadingeUsedPoint,
  setData2,
  data2,
}) {
  const { t } = useTranslation();

  if (loadingeUsedPoint) {
    return (
      <div className="loading" style={{ height: "50vh" }}>
        <CircularIndeterminate />
      </div>
    );
  }
  const xAxisData = info?.map((earnings) => earnings?.month);
  const seriesData = info?.map((earnings) => earnings?.points);

  const handleMonthChange = (dateOption) => {
    setData2({
      year: data2?.year,
    });
    dispatch(
      getAllUsedPoint({
        lang: i18n.language,
        year: data2?.year,
        // month: selectedOption.value,
      })
    );
  };
  return (
    <>
      <div className="col-md-6">
        <div className="form-group">
          <label className="text-dark" htmlFor="first_name">
            {t("Year")}: <sup className="text-danger">*</sup>
          </label>
          <input
            id="year"
            required
            type="year"
            name="year"
            placeholder="قم بادخال رقم السنة التي تريدها"
            onChange={(e) => {
              setData2({ ...data2, year: e.target.value });
            }}
            className="form-control"
          />
        </div>
      </div>

      <div className="w-full mt-2 d-flex justify-content-center ">
        <button
          className="btn btn-danger mx-auto"
          onClick={handleMonthChange}
          type="button"
        >
          قم بالبحث
        </button>
      </div>

      <div style={{ height: "100vh" }}>
        <>
          <LineChart
            xAxis={[{ scaleType: "band", data: xAxisData && xAxisData }]}
            series={[{ data: seriesData && seriesData }]}
            width={1000}
            height={300}
          />
        </>
      </div>
    </>
  );
}
