import { combineReducers, configureStore } from "@reduxjs/toolkit";
import users from "./userslice";
import captains from "./captainSlice";
import states from "./stateSlice";
import areas from "./area";
import sellers from "./seller";
import categories from "./category";
import subcategories from "./subCategory";
import attributes from "./attribute";
import commission from "./commission";
import deliverymethod from "./DeliveryMethod";
import deliveryattrebute from "./deleviryattrebute";
import deliverymessage from "./DeliveyMessage";
import Coupons from "./coupons";
import complaints from "./complaints";
import joinReqs from "./joinreqs";
import banners from "./banners";
import Status from "./orderStatusSlice";
import orders from "./orderSlice";
import gifts from "./gift";
import products from "./product";
import auth from "./authslice";
import roles from "./roleSlice";
import home from "./home";
import statistics from "./statistics";
import themeslice from "./theme";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

// Create a persistConfig for the auth slice
const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["data"], // Specify the properties you want to persist
};

const persistTheme = persistReducer(persistConfig, users);

const customReducer = combineReducers({
  themeslice: themeslice,
  auth: persistReducer(authPersistConfig, auth),
  // Add other reducers as needed
});

const persistCustomReducer = persistReducer(persistConfig, customReducer);

let store = configureStore({
  reducer: {
    persistTheme: persistCustomReducer,
    users: users,
    captains: captains,
    states: states,
    areas: areas,
    categories: categories,
    subcategories: subcategories,
    attributes: attributes,
    sellers: sellers,
    commission: commission,
    deliverymethod: deliverymethod,
    deliverymessage: deliverymessage,
    deliveryattrebute: deliveryattrebute,
    banners: banners,
    gifts: gifts,
    orders: orders,
    products: products,
    roles: roles,
    Status: Status,
    Coupons: Coupons,
    complaints: complaints,
    joinReqs: joinReqs,
    home: home,
    statistics:statistics
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

export default store;
