import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersStatus } from "../../../store/statistics";
import { useTranslation } from "react-i18next";

export default function CircleChart({ info, type }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [series, setSeries] = React.useState([]);

  const couponsColors = [
    "#f32167",
    "#2147f3",
    "#21b3f3",
    "#21f387",
    "#67f321",
    "#f3f321",
    "#f37c21",
    "#f32121",
  ];
  const defaultColors = ["#4caf50", "#2196f3", "#ff2222", "#9c27b0", "#ff9800"];

  useEffect(() => {
  }, [dispatch, i18n.language]);

  useEffect(() => {
    if (info && Array.isArray(info)) {
      const newSeries = info?.map((item, index) => ({
        id: index,
        value: type === "coupons" ? item?.count : item?.value,
        label: item?.label,
      }));
      setSeries(newSeries);
    }
  }, [info, type]);

  if (!info || !Array.isArray(info) || info.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <PieChart
      colors={type === "coupons" ? couponsColors : defaultColors}
      series={[
        {
          data: series,
          innerRadius: 30,
          outerRadius: 100,
          cornerRadius: 5,
          cx: type === "orders" ? 200 : 100,
          cy: type === "orders" ? 200 : 100,
        },
      ]}
      width={500}
      height={500}
    />
  );
}
