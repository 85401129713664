import "./PopUpSellers.scss";
import React, { Fragment, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import { getAllAreas, resetsingleDataArea } from "../../../store/area";
import { notifyError } from "../../../Notification";
import {
  createSeller,
  getAllSellers,
  getSingleSeller,
  resetDataCreate,
  resetDataUpdate,
  updateSeller,
} from "../../../store/seller";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function PopUpSellers({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  const [t, i18n] = useTranslation();

  // let {
  //   data: tags,
  //   loadingBTN,
  //   error,
  //   dataCreate,
  // } = useSelector((state) => state.tags);

  let {
    data: areas,
    loading: loadingAreas,
    error,
  } = useSelector((state) => state.areas);

  useEffect(() => {
    dispatch(getAllAreas({ lang: i18n.language }));
  }, []);

  let {
    data: states,
    loadingBTN,
    error: errorSeller,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.sellers);

  let [statusDis, setstatusDis] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllSellers(i18n.language));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      dispatch(resetsingleDataArea(false));
    }
  }, [dataCreate, dataUpdate]);

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      if (inp.area_id === undefined) {
        notifyError("Chose Area Please");
      }
      if (inp.type === undefined) {
        notifyError("Chose Type Please");
      }
      if (inp.area_id && inp.type) {
        dispatch(
          createSeller({ data: { ...inp, area_id: 1 }, lang: i18n.language })
        );
      }
    }
    if (type === "edit") {
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateSeller({ id: singleData.id, data: newdata, lang: i18n.language })
      );
    }
  };

  console.log("inpp=", inp);
  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  // useEffect(() => {
  //   dispatch(getAllAreas({ lang: i18n.language }));
  // }, []);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleSeller({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  useEffect(() => {
    if (type === "edit") {
      setstatusDis(
        singleData && singleData.discount_status === "0" ? false : true
      );
    }
  }, [singleData]);

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Seller")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Seller")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
            <p className="d-flex justify-content-center btn btn-danger mx-auto " style={{width:"60%"}}>{t('Enter_Add_Lang')}</p>

              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Seller Name")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the seller name"
                  defaultValue={
                    type === "edit" ? singleData && singleData.name : ""
                  }
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label className="text-dark" htmlFor="fname">
                  {t("Type")}: <sup className="redstar">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  onChange={(e) => getValue(e)}
                  name="type"
                  defaultValue={
                    type === "edit"
                      ? singleData && singleData.type
                      : "select Value Please"
                  }
                >
                  <option
                    disabled={true}
                    value={
                      type === "edit"
                        ? singleData && singleData.type
                        : "select Value Please"
                    }
                  >
                    {type === "edit"
                      ? singleData && singleData.type
                      : "select Value Please"}
                  </option>
                  <option value={"external"}>{t("external")}</option>
                  <option value={"internal"}>{t("internal")}</option>
                </select>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label className="text-dark" htmlFor="fname">
                  {t("Area")}: <sup className="redstar">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  onChange={(e) => getValue(e)}
                  name="area_id"
                  defaultValue={
                    type === "edit"
                      ? singleData && singleData.area?.name
                        ? singleData && singleData.area?.name
                        : t("Select Value Please")
                      : t("Select Value Please")
                  }
                >
                  <option
                    disabled={true}
                    value={
                      type === "edit"
                        ? singleData && singleData.area?.name
                          ? singleData && singleData.area?.name
                          : t("Select Value Please")
                        : t("Select Value Please")
                    }
                  >
                    {type === "edit"
                      ? singleData && singleData.area?.name
                        ? singleData && singleData.area?.name
                        : t("Select Value Please")
                      : t("Select Value Please")}
                  </option>
                  {loadingAreas ? (
                    <option>{t("Loading")}...</option>
                  ) : (
                    areas &&
                    areas.map((st) => (
                      <option key={st.id} value={st.id}>
                        {st.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <div className="form-group">
                <p>
                  {t("Status")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    data ? (data.status === "0" ? false : true) : false
                  }
                  onChange={(event) => handleSwitchChange(event, "status")}
                />
              </div>
              <div className="form-group">
                <p>
                  {t("Discount")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    singleData
                      ? singleData.discount_status === "0"
                        ? false
                        : true
                      : false
                  }
                  onChange={(event) => {
                    handleSwitchChange(event, "discount_status");
                    setstatusDis(!statusDis);
                  }}
                />
              </div>
            </div>

            {statusDis && (
              <Fragment>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleInputSlug">
                      {t("Discount")}: <sup className="redstar">*</sup>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="discount"
                      id="exampleInputPassword1"
                      required
                      placeholder="Discount %"
                      defaultValue={data && data.discount}
                      onChange={(e) => getValue(e)}
                    />
                  </div>
                </div>

                {/*
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputSlug">
                    {t("Duration Discount")}: <sup className="redstar">*</sup>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="duration_discount"
                    id="exampleInputPassword1"
                    required
                    placeholder=""
                    onChange={(e) => getValue(e)}
                  />
                </div>
              </div>
            */}
              </Fragment>
            )}

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />{" "}
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpSellers;
