import React, { useEffect, useState } from "react";
// import "./PopUpProductStatus.scss";

import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminate from "../../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../../components/ErrorCompo/ErrorCompo";
import { getAllOrders, resetDataCreate } from "../../../../store/orderSlice";
import { notifyError } from "../../../../Notification";
import {
  ChangeOrderStatus,
  cancelOrder,
  resetDataUpdate,
} from "../../../../store/orderStatusSlice";

function PopUpProductStatus({ handleClose, type, data, title }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");
  let [reason, setReason] = useState("");

  console.log("data==>", data);
  console.log("type==>", type);
  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();

  let {
    loadingBTNTwo,
    error,
    dataCreate,
    dataUpdateStatus,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.Status);

  useEffect(() => {
    if (dataUpdateStatus) {
      dispatch(getAllOrders({ lang: i18n.language }));
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      handleClose();
    }
  }, [dataUpdateStatus]);

  // useEffect(() => {
  //   if (type === "edit") {
  //     dispatch(getSingleGift({ id: data.id, lang: i18n.language }));
  //   }
  // }, [type]);

  //   let getValue = (e) => {
  //     setInp((prev) => {
  //       return { ...prev, [e.target.name]: e.target.value };
  //     });
  //   };

  let [selectedValue, setSelectedValue] = useState("");

  let handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    console.log("e.target.value", e.target.value);
  };

  let onsubmitfn = (e) => {
    console.log("selectedValue==>", selectedValue);
    e.preventDefault();

    let newdata = {
      // status: parseInt(selectedValue),
      _method: "POST",
      product_id:data.id,
      product_status:parseInt(selectedValue)
    };
    if (!selectedValue) {
      notifyError("Chose Status Please");
    }
    dispatch(
      ChangeOrderStatus({
        id: data.id,
        data: newdata,
        lang: i18n.language,
      })
    );
  };
  useEffect(() => {
    if (data?.status === "Delivered") {
      setSelectedValue(1);
    }else if (data?.status === "NotDelivered"){
      setSelectedValue(2);

    }
  }, [data?.status]);
  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            <span>{t("Change Status")}</span>
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
              <div className="radio-group">


      

                <div className="box-radio">
                  <input
                    type="radio"
                    id="delivered"
                    name="status"
                    value="1"
                    defaultChecked={data.status === "Deliverd"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="delivered">{t("Deliverd")}</label>
                </div>
                <div className="box-radio">
                  <input
                    type="radio"
                    id="NotDelivered"
                    name="status"
                    value="0"
                    defaultChecked={data.status === "NotDelivered"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="NotDelivered">{t("NotDelivered")}</label>
                </div>

           
        
              </div>
            </div>
            <div className="btn-lest mt-3">
              {loadingBTNTwo ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {t("Change")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpProductStatus;
