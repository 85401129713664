import React, { useEffect, useState } from "react";
import "./MainProduct.scss";
import Datatable from "../../../components/datatable/Datatable";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import Navbar from "../../../components/Navbar/Navbar";
import Actions from "../../../components/Actions/Actions";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import img1 from "../../../assits/empty-image.jpg";
import img2 from "../../../assits/corn.jpg";
import img3 from "../../../assits/p8dons.jpg";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts, resetsingleDataProduct } from "../../../store/product";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function MainProduct() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProducts({ lang: i18n.language }));
    dispatch(resetsingleDataProduct(""));
  }, []);

  let { 
    data: products,
    loading,
    error,
  } = useSelector((state) => state.products);
  

  // let data = [
  //   {
  //     id: 1,
  //     name: "Anwar",
  //     status: "0",
  //     seller: "malki",
  //     discount: 0,
  //     price: 12000,
  //     img: img2,
  //     availability: "Available",
  //   },
  //   {
  //     id: 2,
  //     name: "wassem",
  //     status: "1",
  //     seller: "malki",
  //     discount: 0,
  //     price: 12000,
  //     img: img3,
  //     availability: "Not Available",
  //   },
  //   {
  //     id: 3,
  //     name: "baraa",
  //     status: "1",
  //     seller: "mazeh",
  //     discount: 0,
  //     price: 25000,
  //     img: img2,
  //     availability: "Available",
  //   },
  //   {
  //     id: 4,
  //     name: "mayar",
  //     status: "0",
  //     seller: "rukn al den",
  //     discount: 0,
  //     price: 15500,

  //     availability: "Available",
  //   },
  // ];

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 80 },
    {
      field: "name",
      headerName: t("Product"),
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.image ? (
              <img className="cellImg" src={params.row.image} alt="avatar" />
            ) : (
              <div className="boxImageEmpty">
                <img className="cellImg" src={img1} alt="avatar" />
              </div>
            )}
            {`${params.row.name}`}
          </div>
        );
      },
    },
    {
      field: "seller",
      headerName: t("Sellers"),
      width: 170,
      renderCell: (params) => {
        return <div>{`${params.row.seller?.name}`}</div>;
      },
    },

    {
      field: "price",
      headerName: t("Price"),
      width: 130,
    },
    {
      field: "availability",
      headerName: t("Availability"),
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className={
              params.row.availability === "1" ? "Available" : "notAvailable"
            }
          >{`${
            params.row.availability === "1" ? "Available" : "notAvailable"
          }`}</div>
        );
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 100,
      renderCell: (params) => {
        return (
          <div className="status">
            <Switch
              {...label}
              defaultChecked={params.row.status === "1" ? true : false}
            />
          </div>
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="Product" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let [showfilter, setShowFilter] = useState(false);
  let [checkprice, setCheckPrice] = useState(false);
  let [checkstatus, setCheckStatus] = useState(false);

  // get check for filter
  let getAllCheck = (name) => {
    if (name === "checkprice") {
      setCheckPrice(!checkprice);
    } else if (name === "checkstatus") {
      setCheckStatus(!checkstatus);
    } else {
      console.log("none");
    }
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <form className={`filter-box ${showfilter && "filter-box-show"}`}>
          <div className="header-filter">
            <h6>{t("Filtered")}</h6>
            <CloseIcon
              className="icon-filter"
              onClick={() => {
                setShowFilter(!showfilter);
              }}
            />
          </div>
          <div className="filter-price">
            <div className="filter-price-show">
              <span>{t("Price")}</span>
              <input
                name="check-price"
                className="check-price"
                type="checkbox"
                value={checkprice}
                onChange={() => {
                  getAllCheck("checkprice");
                }}
              />
            </div>
            {checkprice && (
              <div className=" div-inp d-flex justify-content-center align-items-center gap-2 mb-4 mt-2">
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label style={{ color: "gray" }}>From</label>
                  <input
                    type="number"
                    name="number-from"
                    style={{ width: "40%" }}
                  />
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label style={{ color: "gray" }}>To</label>
                  <input
                    type="number"
                    name="number-to"
                    style={{ width: "40%" }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="filter-status">
            <div className="filter-status-show">
              <p>{t("Status")}</p>
              <input
                name="check-status"
                className="check-status"
                type="checkbox"
                value={checkstatus}
                onChange={() => {
                  getAllCheck("checkstatus");
                }}
              />
            </div>
            {checkstatus && (
              <div className="radio-inp">
                <div className="radio-inp">
                  <label>{t("Availability")}</label>
                  <input type="radio" name="status" />
                </div>
                <div className="radio-inp">
                  <label>{t("Not Availability")}</label>
                  <input type="radio" name="status" />
                </div>
              </div>
            )}
          </div>

          <div className="filter-category">
            <div className="filter-category-show">
              <span>{t("Category")}</span>
              <input
                name="check-category"
                className="check-category"
                type="checkbox"
              />
            </div>
          </div>

          <div className="filter-category">
            <div className="filter-category-show">
              <span>{t("Sub Category")}</span>
              <input
                name="check-category"
                className="check-category"
                type="checkbox"
              />
            </div>
          </div>

          <div className="filter-category">
            <div className="filter-category-show">
              <div className="form-group w-100">
                <span>{t("Seller")}</span>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    width: "100%",
                    marginBottom: "12px",
                  }}
                  className="form-select mt-2"
                  name="seller_id"
                  defaultValue={t("Select Value Please")}
                >
                  <option disabled={true} value={t("Select Value Please")}>
                    {t("Select Value Please")}
                  </option>
                  <option value={1}>{t("zamalka")}</option>
                  <option value={2}>{t("harasta")}</option>
                </select>
              </div>
            </div>
          </div>

          <div className="btn-list">
            <button className="btn btn-reset" type="reset">
              <RestartAltIcon className="icon-b" />
              {t("Reset Filter")}
            </button>

            <button className="btn btn-apply">
              <CheckIcon className="icon-b" />
              {t("Apply Filter")}
            </button>
          </div>
        </form>

        <div className="main-header">
          <h3>{t("Product")}</h3>
          <div className="btn-list">
            <Link to={`/product/add`} className="btn">
              <AddIcon /> {t("Add New")}
            </Link>
            <button className="btn btn-delete">
              <ModalDelete
                filter="Product"
                params={products}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>

            {/* <button
              className="btn btn-filter"
              onClick={() => {
                setShowFilter(!showfilter);
              }}
            >
              <FilterAltOffIcon
                className="icon-b"
                onClick={() => {
                  setShowFilter(true);
                }}
              />
              {t("Filter")}
            </button> */}
          </div>
        </div>
        <div className="table">
          {error ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={products && products}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainProduct;
