import { Box, Pagination } from "@mui/material";
import React from "react";

const PaginationList = ({
  totalPages,
  currentPage,
  handlePageChange,
  _bgcolor,
}) => {
  return (
    <Box
      spacing={2}
      sx={{
        borderTopLeftRadius: "0",
        bgcolor: _bgcolor,
        borderTopRightRadius: "0",
        justifyContent: "center",
        position: "relative",
        // bottom: 0,
        left: "50%",
        transform: "translate(-50%)",
      }}
    >
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="warning"
        size="small"
        sx={{
          // direction:"rtl",
          display: "block",
          margin: "auto",
          padding: "0.5em",
          "& .MuiPaginationItem-root": {
            fontFamily: "inherit",
            fontWeight: "bold",
            fontSize: "1rem",
            borderRadius: "8px",
            margin: "0 4px",
            padding: "8px 16px",
          },
          "& .Mui-selected": {
            backgroundColor: "#4F8640",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#4F8640",
            },
          },
        }}
      />
    </Box>
  );
};

export default PaginationList;
