import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar/Navbar";
import TrackingMap from "./TrackingMap";
import io from "socket.io-client";
function Tracking() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let dispatch = useDispatch();

  const [driveres, setDriveres] = useState([]);
  useEffect(() => {
    const socket = io("ws://farmy.tracking.peaklink.site:3000");

    // Event listener for successful connection

    // Event listener for successful connection
    socket.on("connect", () => {
      console.log("Connected to server");
      // You can perform actions here after successful connection
    });

    socket.on("track_all_drivers", (driver) => {
      console.log("Received message under track_all_drivers:", driver);

      setDriveres((prevDrivers) => {
        // Check if the driver already exists in the drivers array
        const driverExists = prevDrivers.some(
          (d) => d.driver_id === driver.driver_id
        );

        if (driverExists) {
          // If the driver exists, update their data
          return prevDrivers.map((d) =>
            d.driver_id === driver.driver_id ? driver : d
          );
        } else {
          // If the driver doesn't exist, add them to the array
          return [...prevDrivers, driver];
        }
      });
    });

    // Event listener for disconnect
    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    // Clean up on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div>
          <TrackingMap driveres={driveres} />
        </div>
      </div>
    </div>
  );
}

export default Tracking;
