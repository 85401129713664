import React from "react";
import "./PopUpDeleteHome.scss";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { deleteHomePage, getAllHomeData } from "../../../store/home";

function PopUpDeleteHome({ data, handleClose, type, selectedRowIds }) {
  console.log("data", data);
  let dispatch = useDispatch();
  let dataobj = new FormData();
  dataobj.append("_method", "DELETE");
  let onsubmitfn = (e) => {
    e.preventDefault();
    dispatch(deleteHomePage({ id: data.id, data: dataobj })).then((action) => {
      if (deleteHomePage.fulfilled.match(action)) {
        dispatch(getAllHomeData({ lang: i18n.language, page: 1 }));
      }
    });
  };
  const [t, i18n] = useTranslation();

  return (
    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
      {type === "all" ? (
        selectedRowIds.length <= 0 ? (
          <div
            className="p-2"
            style={{ color: "red", fontSize: "22px", fontWeight: 700 }}
          >
            {t("There Are No Rows Seleceted")}
          </div>
        ) : (
          <form className="waning" onSubmit={(e) => onsubmitfn(e)}>
            <h2>{t("Are You Sure ?")}</h2>
            <p className="message">
              {t("Do you really want to delete")} {t("All Selected")}?{" "}
              {t("This process cannot be undone.")}
            </p>

            <div className="btnleist-delte">
              <button className="btn btn-y" onClick={handleClose}>
                {t("Yes")}
              </button>
              <span className="btn btn-n" onClick={handleClose}>
                {t("No")}
              </span>
            </div>
          </form>
        )
      ) : (
        <form className="waning" onSubmit={(e) => onsubmitfn(e)}>
          <h2>{t("Are You Sure ?")}</h2>
          <p className="message">
            {t("Do you really want to delete")}
            {data?.row?.tag}? {t("This process cannot be undone.")}
          </p>

          <div className="btnleist-delte">
            <button
              className="btn btn-y"
              onClick={() => {
                handleClose();
              }}
            >
              {t("Yes")}
            </button>
            <span className="btn btn-n" onClick={handleClose}>
              {t("No")}
            </span>
          </div>
        </form>
      )}
    </Typography>
  );
}

export default PopUpDeleteHome;
