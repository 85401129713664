import React, { useEffect, useState } from "react";
import "./PopUpHome.scss";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  createCoupon,
  getAllCoupons,
  resetDataCreate,
  resetDataUpdate,
  setSingleData,
  updateCoupon,
} from "../../../store/coupons";
import Select from "react-select";
import { getAllBanners } from "../../../store/banners";
import { getAllcategory } from "../../../store/category";
import { getAllProducts } from "../../../store/product";
import { Spinner } from "react-bootstrap";
import {
  createHomePageSection,
  getAllHomeData,
  updateHomePageSection,
} from "../../../store/home";

function PopUphomeDynamic({ handleClose, type, data }) {
  const [t, i18n] = useTranslation();
  console.log("data",data);

  let selectOptions = [
    { label: "category", value: "category" },
    { label: "section", value: "section" },
    { label: "slider", value: "slider" },
  ];
  const [options, setOptions] = useState(selectOptions);
  const [contentDefultOptions, setContentDefultOptions] = useState([]);

  let [inp, setInp] = useState("");
  let [selectedContent, setSelectedContent] = useState("");
  const [contentOptions, setContentOptions] = useState([]);
  const [selectedContentOptions, setSelectedContentOptions] = useState([]);
  let { loading: productsLoading } = useSelector((state) => state.products);
  let { loading: categoriesLoading } = useSelector((state) => state.categories);
  let { loading: bannersLoading } = useSelector((state) => state.banners);

  let dispatch = useDispatch();
  let {
    data: coupons,
    loadingBTN,
    loading: createLoading,
    types,
    error,
    dataUpdate,
    dataCreate,
    typesLoading,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.home);

  // useEffect(() => {
  //   if (dataCreate || dataUpdate) {
  //     handleClose();
  //     dispatch(getAllCoupons({ lang: i18n.language }));
  //     dispatch(resetDataCreate(false));
  //     dispatch(resetDataUpdate(false));
  //     // dispatch(resetAllCouponsAction(""));
  //   }
  // }, [dataCreate, dataUpdate]);



  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const onsubmitfn = (e) => {
    e.preventDefault();

    const contentValues = selectedContentOptions.map((option) => option.value);
    if (type === "add") {
      dispatch(
        createHomePageSection({
          data: { ...inp, type: selectedContent, content: contentValues },
          lang: i18n.language,
        })
      ).then((action) => {
        if (createHomePageSection.fulfilled.match(action)) {
          dispatch(getAllHomeData({ lang: i18n.language, page: 1 }));
          handleClose();
        }
      });
    }
    if (type === "edit") {
      dispatch(
        updateHomePageSection({
          data: { ...inp, type: selectedContent, content: contentValues },
          lang: i18n.language,
        })
      ).then((action) => {
        if (updateHomePageSection.fulfilled.match(action)) {
          dispatch(getAllHomeData({ lang: i18n.language, page: 1 }));
          handleClose();
        }
      });
    }
  };

  const getContent = (selectedValue) => {
    const actions = {
      category: () =>
        dispatch(getAllcategory({ lang: i18n.language })).then((action) => {
          if (getAllcategory.fulfilled.match(action)) {
            const responseData = action.payload;
            let options = responseData.map((option) => ({
              label: option.name,
              value: option.id,
            }));

            setContentOptions(options);
          }
        }),
      section: () =>
        dispatch(getAllProducts({ lang: i18n.language })).then((action) => {
          if (getAllProducts.fulfilled.match(action)) {
            const responseData = action.payload;
            let options = responseData.map((option) => ({
              label: option.name,
              value: option.id,
            }));

            setContentOptions(options);
          }
        }),
      slider: () =>
        dispatch(getAllBanners({ lang: i18n.language })).then((action) => {
          if (getAllBanners.fulfilled.match(action)) {
            const responseData = action.payload;
            let options = responseData.map((option) => ({
              label: option.type,
              value: option.id,
            }));

            setContentOptions(options);
          }
        }),
    };

    const action = actions[selectedValue.value];
    if (action) action();
  };
  const getTypeValue = () => {
    if (type === "edit" && data?.type) {
      return options?.find((option) => option.label === data?.type);
    }
  };

  useEffect(() => {
    if (data?.id) {
      setInp({
        id: data?.id,
        title_en: data?.title?.en,
        title_ar: data?.title?.ar,
        order: data?.order,
        content: data?.content,
        type: data?.type,
      });
      setSelectedContent(data?.type )

      if (data?.type === "category") {
        dispatch(getAllcategory({ lang: i18n.language })).then((action) => {
          if (getAllcategory.fulfilled.match(action)) {
            const responseData = action.payload;
            const filteredOptions = responseData.filter(
              (item) =>
                !data.content.find((contentItem) => contentItem.id === item.id)
            );
            setContentOptions(
              filteredOptions.map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        });
        setOptions(
          selectOptions.filter((option) => option.label !== data.type)
        );
      }
      if (data?.type === "section") {
        dispatch(getAllProducts({ lang: i18n.language })).then((action) => {
          if (getAllProducts.fulfilled.match(action)) {
            const responseData = action.payload;
            const filteredOptions = responseData.filter(
              (item) =>
                !data.content.find((contentItem) => contentItem.id === item.id)
            );
            setContentOptions(
              filteredOptions.map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
            setOptions(
              selectOptions.filter((option) => option.label !== data.type)
            );
          }
        });
      }
      if (data?.type === "slider") {
        dispatch(getAllBanners({ lang: i18n.language })).then((action) => {
          if (getAllBanners.fulfilled.match(action)) {
            const responseData = action.payload;
            const filteredOptions = responseData.filter(
              (item) =>
                !data.content.find((contentItem) => contentItem.id === item.id)
            );
            setContentOptions(
              filteredOptions.map((item) => {
                return {
                  label: item.type,
                  value: item.id,
                };
              })
            );
            setOptions(
              selectOptions.filter((option) => option.label !== data.type)
            );
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (type === "edit" && data?.content) {
      if (data?.type !== "slider") {
        setContentDefultOptions(
          data?.content?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } else {
        setContentDefultOptions(
          data?.content?.map((item) => ({
            label: item.type,
            value: item.id,
          }))
        );
      }
    }
  }, [data]);



  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Element")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Element")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Type")}: <sup className="redstar">*</sup>
                </label>
                <Select
                  options={options}
                  onChange={(selectedValue) => {
                    setSelectedContent(selectedValue.value);
                    getContent(selectedValue);

                    setOptions(
                      selectOptions.filter(
                        (option) => option.label !== selectedValue.value
                      )
                    );
                    if (type === "edit") setContentDefultOptions([]);
                  }}
                  defaultValue={type === "edit" ? getTypeValue() : null}
                />

                <label htmlFor="exampleInputSlug">
                  {t("title_en")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="title_en"
                  required
                  placeholder="Enter the title in English"
                  defaultValue={type === "edit" ? data.title.en : ""}
                  onChange={(e) => getValue(e)}
                />
                <label htmlFor="exampleInputSlug">
                  {t("title_ar")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="title_ar"
                  required
                  placeholder="Enter the title in Arabic"
                  defaultValue={type === "edit" ? data.title.ar : ""}
                  onChange={(e) => getValue(e)}
                />
                <label htmlFor="exampleInputSlug">
                  {t("order")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="order"
                  required
                  placeholder="Enter the Order"
                  defaultValue={type === "edit" ? data.order : ""}
                  onChange={(e) => getValue(e)}
                />
                <label htmlFor="exampleInputSlug">
                  {t("Content")}: <sup className="redstar">*</sup>
                </label>
                {bannersLoading || categoriesLoading || productsLoading ? (
                  <Spinner
                    style={{ display: "block" }}
                    animation="border"
                    size="sm"
                  />
                ) : (
                  <Select
                    options={contentOptions}
                    isMulti
                    onChange={(selectedValues) => {
                      setSelectedContentOptions(selectedValues);
                    }}
                    defaultValue={type === "edit" ? contentDefultOptions : null}
                  />
                )}
              </div>
            </div>

            <div className="btn-lest mt-3">
              {createLoading ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUphomeDynamic;
