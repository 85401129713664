import React, { useEffect, useRef, useState } from "react";
import "./CreateEditProduct.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import { useTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Switch } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";

import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  getAllProducts,
  getSingleProduct,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataProduct,
  updateProdcut,
} from "../../../store/product";
import { getAllcategory } from "../../../store/category";
import {
  getAllSubCategory,
  getAllSubCategoryByCat,
} from "../../../store/subCategory";
import { getAllAttribute, getValueAttribute } from "../../../store/attribute";
import { getAllSellers } from "../../../store/seller";
import AttrAndValProduct from "../../../components/AttrAndValProduct/AttrAndValProduct";
import { getAllCommission } from "../../../store/commission";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import { notifyError } from "../../../Notification";

function CreateEditProduct({ handleClose, type, data }) {
  let { name, id } = useParams();
  const [t, i18n] = useTranslation();
  const editorRef = useRef(null);
  let navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let goBackFn = () => {
    navigate(-1);
  };

  let [inp, setInp] = useState("");
  let dispatch = useDispatch();

  let [getAttre, setGetAttre] = useState("");
  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.products);

  // useEffect(() => {
  //   if (dataCreate || dataUpdate) {
  //     // dispatch(getAllProducts({ lang: i18n.language }));
  //     dispatch(resetDataCreate(false));
  //     dispatch(resetDataUpdate(false));
  //     dispatch(resetsingleDataProduct(""));
  //   }
  // }, [dataCreate, dataUpdate]);

  let getdata = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let getdataImage = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.files[0] };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let handleInputChange = (event, inputName) => {
    setInp((prev) => ({
      ...prev,
      [inputName]: event, // Use the editorContent state
    }));
  };

  let {
    data: categories,
    loading: loadingcate,
    error: errorCategory,
  } = useSelector((state) => state.categories);

  let {
    data: subcategories,

    loading: loadingSubCat,
    error: errorSubCat,
  } = useSelector((state) => state.subcategories);

  let {
    data: sellers,
    loading: loadingSeller,
    error: errorSeller,
  } = useSelector((state) => state.sellers);

  let {
    data: commission,
    loading: loadingCommetion,
    error: errorComtion,
  } = useSelector((state) => state.commission);

  const {
    data: attributes,
    loading: loadingAttr,
    error: errorAttre,
    values,
    loadingValue,
    errorValue,
  } = useSelector((state) => state.attributes);

  useEffect(() => {
    dispatch(getAllcategory({ lang: i18n.language }));
    dispatch(getAllSellers({ lang: i18n.language }));
    dispatch(getAllCommission({ lang: i18n.language }));
    dispatch(getAllAttribute({ lang: i18n.language }));
  }, []);

  useEffect(() => {
    if (name === "edit") {
      dispatch(getSingleProduct({ id: id, lang: i18n.language }));
    }
  }, [id]);

  let getSubCate = (e) => {
    dispatch(
      getAllSubCategoryByCat({ lang: i18n.language, id: e.target.value })
    );
  };

  // console.log("singleDatasingleData= >", singleData);
  // console.log("name=>", name);

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (name === "add") {
      let newdata = {
        ...inp,
        attributes: getAttre,
      };
      if (getAttre.length <= 0) {
        notifyError("Attrebute Field Is Required");
      }
      if (getAttre.length > 0) {
        dispatch(createProduct({ data: newdata, lang: i18n.language })).then(
          (action) => {
            if (createProduct.fulfilled.match(action)) {
              navigate("/product");
            }
          }
        );
      }
    }
    if (name === "edit") {
      let newdata = {
        ...inp,
        attributes: getAttre,
        // _method: "PUT",
      };
      dispatch(
        updateProdcut({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      ).then((action) => {
        if (updateProdcut.fulfilled.match(action)) {
          navigate("/product");
        }
      });
    }
  };

  useEffect(() => {
    setInp({
      // image: singleData.image,
      discount_status: singleData.discount_status,
    });
  }, [singleData]);
  // console.log("inp*/*/*/", inp);
  // console.log("getAttre*/*/*/", getAttre);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header  add-header">
          {name === "add" ? (
            <h4>{t("Create Product")}</h4>
          ) : (
            <h4>{t("Edit Product")}</h4>
          )}

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row box-inps" onSubmit={(e) => onsubmitfn(e)}>
            <p className="d-flex justify-content-center btn btn-danger mx-auto " style={{width:"60%"}}>{t('Enter_Add_Lang')}</p>
            <div className="col-md-6 row">

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="name">
                    {t("Name")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="name"
                    required
                    type="text"
                    name="name"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Product Name"
                    className="form-control"
                    defaultValue={singleData.name ? singleData.name : ""}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group upload-image-box">
                  <label className="text-dark">
                    {t("Uplaod Image")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="uplaod"
                    type="file"
                    name="image"
                    onChange={(e) => {
                      getdataImage(e);
                    }}
                    placeholder="Please Enter Product Name"
                    className="form-control"
                  />
                  <div className="upload-box">
                    {(inp.image || singleData.image) && (
                      <img
                        src={
                          inp.image instanceof File
                            ? URL.createObjectURL(inp.image)
                            : inp.image
                            ? inp.image
                            : singleData.image
                        }
                        alt="...."
                      />
                    )}

                    {inp.image || singleData.image ? (
                      <label className="small-upload" htmlFor="uplaod">
                        <FileUploadIcon />
                      </label>
                    ) : (
                      <label className="big-upload" htmlFor="uplaod">
                        <AddIcon />
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="price">
                    {t("Price")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="price"
                    required
                    type="number"
                    name="price"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Product Price"
                    className="form-control"
                    defaultValue={singleData.price}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="real_price">
                    {t("real_price")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="real_price"
                    required
                    type="number"
                    name="real_price"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Product Real Price"
                    className="form-control"
                    defaultValue={singleData.price}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="quantity">
                    {t("Quantity")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="quantity"
                    required
                    type="number"
                    name="quantity"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Product quantity"
                    className="form-control"
                    defaultValue={singleData.quantity}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="tax">
                    {t("Tax")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="tax"
                    required
                    type="number"
                    name="tax"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Product tax"
                    className="form-control"
                    defaultValue={singleData.tax}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="unit">
                    {t("Unit")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="unit"
                    required
                    type="text"
                    name="unit"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Product tax"
                    className="form-control"
                    defaultValue={singleData.tax}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="Seller">
                    {t("Seller")}: <sup className="text-danger">*</sup>
                  </label>
                  <select
                    style={{
                      BorderColor: "gray",
                      padding: "3px 30px",
                      color: "gray",
                      marginBottom: "8px",
                    }}
                    className="form-select"
                    onChange={(e) => getdata(e)}
                    name="seller_id"
                    defaultValue={
                      name === "edit"
                        ? singleData && singleData.seller?.name
                          ? singleData && singleData.seller?.name
                          : t("Select Value Please")
                        : t("Select Value Please")
                    }
                  >
                    <option
                      disabled={true}
                      value={
                        name === "edit"
                          ? singleData && singleData.seller?.name
                            ? singleData && singleData.seller?.name
                            : t("Select Value Please")
                          : t("Select Value Please")
                      }
                    >
                      {name === "edit"
                        ? singleData && singleData.seller?.name
                          ? singleData && singleData.seller?.name
                          : t("Select Value Please")
                        : t("Select Value Please")}
                    </option>
                    {sellers &&
                      sellers.map((e, index) => {
                        return (
                          <option key={index} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="commission">
                    {t("Commission")}: <sup className="text-danger">*</sup>
                  </label>
                  <select
                    style={{
                      BorderColor: "gray",
                      padding: "3px 30px",
                      color: "gray",
                      marginBottom: "8px",
                    }}
                    className="form-select"
                    onChange={(e) => getdata(e)}
                    name="commission_id"
                    defaultValue={
                      name === "edit"
                        ? singleData && singleData.commission_id
                          ? singleData && singleData.commission_id
                          : t("Select Value Please")
                        : t("Select Value Please")
                    }
                  >
                    <option
                      disabled={true}
                      value={
                        name === "edit"
                          ? singleData && singleData.commission_id
                            ? singleData && singleData.commission_id
                            : t("Select Value Please")
                          : t("Select Value Please")
                      }
                    >
                      {name === "edit"
                        ? singleData && singleData.commission_id
                          ? singleData && singleData.commission_id
                          : t("Select Value Please")
                        : t("Select Value Please")}
                    </option>
                    {commission &&
                      commission.map((e, index) => {
                        return (
                          <option key={index} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="name">
                    {t("Commission Value")}:{" "}
                    <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="commission_value"
                    required
                    type="number"
                    name="commission_value"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Commission Value Name"
                    className="form-control"
                    defaultValue={
                      singleData.commission_value
                        ? singleData.commission_value
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="Category">
                    {t("Category")}: <sup className="text-danger">*</sup>
                  </label>
                  <select
                    style={{
                      BorderColor: "gray",
                      padding: "3px 30px",
                      color: "gray",
                      marginBottom: "8px",
                    }}
                    className="form-select"
                    onChange={(e) => {
                      getdata(e);
                      getSubCate(e);
                    }}
                    name="category_id"
                    defaultValue={
                      name === "edit"
                        ? singleData && singleData.category?.name
                          ? singleData && singleData.category?.name
                          : t("Select Value Please")
                        : t("Select Value Please")
                    }
                  >
                    <option
                      disabled={true}
                      value={
                        name === "edit"
                          ? singleData && singleData.category?.name
                            ? singleData && singleData.category?.name
                            : t("Select Value Please")
                          : t("Select Value Please")
                      }
                    >
                      {name === "edit"
                        ? singleData && singleData.category?.name
                          ? singleData && singleData.category?.name
                          : t("Select Value Please")
                        : t("Select Value Please")}
                    </option>
                    {categories &&
                      categories.map((e, index) => {
                        return (
                          <option key={index} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="Sub-Category">
                    {t("Sub-Category")}: <sup className="text-danger">*</sup>
                  </label>
                  <select
                    style={{
                      BorderColor: "gray",
                      padding: "3px 30px",
                      color: "gray",
                      marginBottom: "8px",
                    }}
                    className="form-select"
                    onChange={(e) => getdata(e)}
                    name="subcategory_id"
                    disabled={inp.category_id ? false : true}
                    defaultValue={
                      name === "edit"
                        ? singleData && singleData.subcategory.name
                          ? singleData && singleData.subcategory.name
                          : t("Select Value Please")
                        : t("Select Value Please")
                    }
                  >
                    <option
                      disabled={true}
                      value={
                        name === "edit"
                          ? singleData && singleData.subcategory.name
                            ? singleData && singleData.subcategory.name
                            : t("Select Value Please")
                          : t("Select Value Please")
                      }
                    >
                      {name === "edit"
                        ? singleData && singleData.subcategory.name
                          ? singleData && singleData.subcategory.name
                          : t("Select Value Please")
                        : t("Select Value Please")}
                    </option>
                    {subcategories &&
                      subcategories.map((e, index) => {
                        return (
                          <option key={index} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-md-12   d-flex justify-content-start gap-5 align-items-center">
                <div className="form-group switch">
                  <label className="text-dark">
                    {t("Status")}: <sup className="text-danger">*</sup>
                  </label>
                  <Switch
                    {...label}
                    defaultChecked={
                      singleData && singleData.status === "1" ? true : false
                    }
                    onChange={(event) => handleSwitchChange(event, "status")}
                  />
                </div>

                <div className="form-group switch">
                  <label className="text-dark">
                    {t("Discount Status")}: <sup className="text-danger">*</sup>
                  </label>
                  <Switch
                    {...label}
                    defaultChecked={
                      singleData && singleData.discount_status === "1"
                        ? true
                        : false
                    }
                    onChange={(event) =>
                      handleSwitchChange(event, "discount_status")
                    }
                  />
                </div>
              </div>

              {inp.discount_status ? (
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="text-dark" htmlFor="Discount">
                      {t("Discount")}: <sup className="text-danger">*</sup>
                      <small>( The Discount in %)</small>
                    </label>
                    <input
                      id="discount"
                      required
                      type="number"
                      name="discount"
                      onChange={(e) => {
                        getdata(e);
                      }}
                      placeholder="Please Enter Discount"
                      className="form-control"
                      defaultValue={
                        inp.discount
                          ? inp.discount
                          : singleData && singleData.discount
                      }
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-12">
              <hr />
            </div>

            {loadingAttr ? (
              <div className="loading">
                <CircularIndeterminate />
              </div>
            ) : (
              attributes && (
                <AttrAndValProduct
                  setGetAttre={setGetAttre}
                  singelData={singleData && singleData.attributes}
                  attributes={attributes && attributes}
                />
              )
            )}

            <div className="col-md-12">
              <hr />
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="text-dark" htmlFor="Details">
                  {t("Description")}: <sup className="text-danger">*</sup>
                </label>
                <Editor
                  apiKey="oiu014jb1mdeotwowjjsqrwcyvrhcyctjdn2ql3ept4jgr5p"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={singleData && singleData.description}
                  init={{
                    height: 300,
                    menubar: true,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic forecolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onEditorChange={(content) =>
                    handleInputChange(content, "description")
                  }
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {name === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              {name === "add" && (
                <button type="reset" className="btn btn-danger-rgba">
                  <DoNotDisturbAltIcon color="#fff" /> {t("Reset")}
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default CreateEditProduct;
