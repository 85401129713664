import React, { useEffect, useRef, useState } from "react";
import "./EditCreateCaptin.scss";
import { Switch } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../../../components/Navbar/Navbar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCity,
  GetState,
  createCaptain,
  getSingleCaptain,
  resetsingleDataCaptain,
  updateCaptain,
} from "../../../store/captainSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function EditCreateCaptin() {
  const [showPassword, setShowPassword] = useState(false);
  const [valueTypeIsValid, setValueTypsIsValid] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  let { name, id } = useParams();
  console.log("name",name);
  const [t, i18n] = useTranslation();
  const editorRef = useRef(null);
  let navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let goBackFn = () => {
    navigate(-1);
  };

  let [inp, setInp] = useState("");
  let getdata = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let getdataImage = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.files[0] };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let handleInputChange = (event, inputName) => {
    setInp((prev) => ({
      ...prev,
      [inputName]: event, // Use the editorContent state
    }));
  };

  let dispatch = useDispatch();

  let {
    states,
    loadingBTN,
    loadingSingle,
    singleData,
    errorSingle,
    loadingStates,
    errorStates,
    loadingCity,
    city,
    errorCity,
  } = useSelector((state) => state.captains);

  // console.log("states==>", states);
  // console.log("inp==>", inp);
  // console.log("singleData==>", singleData);

  useEffect(() => {
    dispatch(GetState());
    if (name === "edit") {
      dispatch(getSingleCaptain({ id: id, lang: i18n.language }));
    } else {
      dispatch(resetsingleDataCaptain(""));
    }
  }, [id]);

  useEffect(() => {
    if (name === "add") {
      dispatch(resetsingleDataCaptain(""));
    }
  }, []);
  let sendData = (e) => {
    e.preventDefault();
    if (inp.type_value >= 100 && inp.type == 2) {
      console.log("aasd");
      setValueTypsIsValid(false);
      return;
    }
    let actionToDispatch = name === "edit" ? updateCaptain : createCaptain;

    dispatch(
      actionToDispatch({
        data: name === "edit" ? { ...inp, _method: "PUT" } : inp,
        id: id,
        lang: i18n.language,
      })
    );
    // .then((action) => {
    //   if (actionToDispatch.fulfilled.match(action)) {
    //     navigate("/captains");
    //   }
    // });
  };

  let getLocation = (e, id) => {
    if (e === "city") {
      dispatch(GetCity(id));
    }
  };
  console.log("singleData", singleData);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header add-header">
          {name === "add" ? (
            <h4>{t("Create Caption")}</h4>
          ) : (
            <h4>{t("Edit Caption")}</h4>
          )}

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row box-inps" onSubmit={(e) => sendData(e)}>
            <div className="col-md-6">
              
              <div className="form-group img-upload">
                <label htmlFor="file">
                  <img
                    className="b6e5"
                    src={
                      inp.user_avatar
                        ? URL.createObjectURL(inp.user_avatar)
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt="..."
                  />
                  <CloudUploadIcon className="icon ms-2" />
                </label>
                <input
                  type="file"
                  id="file"
                  name="user_avatar"
                  placeholder="name"
                  style={{ display: "none" }}
                  onChange={(e) => getdataImage(e)}
                />

                <div className="form-group switch">
                  <label className="text-dark">
                    {t("Status")}: <sup className="text-danger">*</sup>
                  </label>
                  <Switch
                    {...label}
                    defaultChecked={
                      singleData && singleData.status === "1" ? true : false
                    }
                    onChange={(event) => handleSwitchChange(event, "status")}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="Role">
                  {t("Role")}: <sup className="text-danger">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  required
                  onChange={(e) => getdata(e)}
                  name="role_id"
                  defaultValue={t("Select Value Please")}
                >
                  <option disabled={true} value={t("Select Value Please")}>
                    {t("Select Value Please")}
                  </option>
                  <option value={1}>{t("admin")}</option>
                  <option value={2}>{t("user")}</option>
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="first_name">
                  {t("First Name")}: <sup className="text-danger">*</sup>
                </label>
                <input
                  id="first-name"
                  required
                  type="text"
                  name="first_name"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  placeholder="Please Enter First Name"
                  defaultValue={singleData ? singleData.first_name : ""}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="last_name">
                  {t("Last Name")}: <sup className="text-danger">*</sup>
                </label>
                <input
                  id="last_name"
                  required
                  type="text"
                  name="last_name"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  placeholder="Please Enter Last Name"
                  defaultValue={singleData ? singleData.last_name : ""}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="email">
                  {t("Email")}: <sup className="text-danger">*</sup>
                </label>
                <input
                  id="email"
                  required
                  type="email"
                  name="email"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  placeholder="Please Enter Email"
                  defaultValue={singleData ? singleData.email : ""}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="password">
                  {t("Password")}: <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex align-items-center gap-1">
                  <input
                    id="password"
                    required={name === "edit" ? false : true}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={(e) => {
                      getdata(e);
                    }}
                    placeholder="Please Enter Password"
                    className="form-control"
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="phone">
                  {t("Phone")}: <sup className="text-danger">*</sup>
                </label>
                <input
                  id="phone"
                  required
                  type="number"
                  name="phone"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  placeholder="Please Enter Phone"
                  defaultValue={singleData ? singleData.phone : ""}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="state_id">
                  {t("State")}: <sup className="text-danger">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  onChange={(e) => getdata(e)}
                  name="state_id"
                  defaultValue={
                    name === "edit"
                      ? singleData && singleData?.city?.state?.name
                        ? singleData?.city?.state?.name
                        : t("Select Value Please")
                      : t("Select Value Please")
                  }
                >
                  <option
                    disabled={true}
                    value={
                      name === "edit"
                        ? singleData && singleData?.city?.state?.name
                          ? singleData?.city?.state?.name
                          : t("Select Value Please")
                        : t("Select Value Please")
                    }
                  >
                    {name === "edit"
                      ? singleData && singleData?.city?.state?.name
                        ? singleData?.city?.state?.name
                        : t("Select Value Please")
                      : t("Select Value Please")}
                  </option>
                  {loadingStates ? (
                    <option>{t("Loading")}...</option>
                  ) : (
                    states &&
                    states.map((st) => (
                      <option key={st.id} value={st.id}>
                        {st.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="city_id">
                  {t("City")}: <sup className="text-danger">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  disabled={
                    name === "edit"
                      ? singleData && singleData?.city?.state?.id
                        ? false
                        : inp.state_id
                        ? false
                        : true
                      : inp.state_id
                      ? false
                      : true
                  }
                  onClick={(e) =>
                    getLocation(
                      "city",
                      inp.state_id
                        ? inp.state_id
                        : singleData && singleData?.city?.state?.id
                    )
                  }
                  onChange={(e) => getdata(e)}
                  name="city_id"
                  defaultValue={
                    name === "edit"
                      ? singleData && singleData.city?.name
                        ? singleData && singleData.city?.name
                        : t("Select Value Please")
                      : t("Select Value Please")
                  }
                >
                  <option
                    disabled={true}
                    value={
                      name === "edit"
                        ? singleData && singleData.city?.name
                          ? singleData && singleData.city?.name
                          : t("Select Value Please")
                        : t("Select Value Please")
                    }
                  >
                    {name === "edit"
                      ? singleData && singleData.city?.name
                        ? singleData && singleData.city?.name
                        : t("Select Value Please")
                      : t("Select Value Please")}
                  </option>

                  {loadingCity ? (
                    <option>{t("Loading")}...</option>
                  ) : (
                    city &&
                    city.map((st) => (
                      <option key={st.id} value={st.id}>
                        {st.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="text-dark"
                  htmlFor="Type_of_financial_returns"
                >
                  {t("Type_of_financial_returns")}:{" "}
                  <sup className="text-danger">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  required
                  onChange={(e) => getdata(e)}
                  name="type"
                  defaultValue={
                    singleData?.type?.type
                      ? singleData?.type?.type
                      : t("Select Value Please")
                  }
                >
                  <option
                    disabled={true}
                    value={t("Select Financial Returns Please")}
                  >
                    {t("Select Financial Returns Please")}
                  </option>
                  <option value={1}>{t("Salary")}</option>
                  <option value={2}>{t("Commision")}</option>
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="text-dark" htmlFor="Vlaue_of_type">
                  {t("Vlaue_of_type")}: <sup className="text-danger">*</sup>
                </label>
                <input
                  id="Vlaue_of_type"
                  required
                  type="number"
                  name="type_value"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  placeholder="Please Enter Financial Returns Value"
                  defaultValue={singleData ? singleData.type.value : ""}
                  className="form-control"
                />
                {!valueTypeIsValid && (
                  <p className="text-danger">
                    {t("Captain's commission must be less than 100%.")}
                  </p>
                )}
              </div>
            </div>

            <div className="btn-lest mt-5">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {name === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              {name === "add" && (
                <button
                  type="reset"
                  className="btn btn-danger-rgba"
                  onClick={() => {
                    setValueTypsIsValid(true);
                  }}
                >
                  <DoNotDisturbAltIcon color="#fff" /> {t("Reset")}
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
export default EditCreateCaptin;
