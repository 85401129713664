import "./PopUpCategory.scss";
import React, { Fragment, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import {
  createCategory,
  getAllcategory,
  getSingleCategory,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDatacategory,
  updateCategory,
} from "../../../store/category";
import { notifyError } from "../../../Notification";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function PopUpCategory({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();
  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.categories);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllcategory({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      dispatch(resetsingleDatacategory(false));
    }
  }, [dataCreate, dataUpdate]);

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      dispatch(createCategory({ data: inp, lang: i18n.language }));
    }
    if (type === "edit") {
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateCategory({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let handelImage = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.files[0] };
    });
  };

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleCategory({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  console.log("inpp..0", inp);
  console.log("singleData", singleData);
  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Category")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Category")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
            <p className="d-flex justify-content-center btn btn-danger mx-auto " style={{width:"60%"}}>{t('Enter_Add_Lang')}</p>

              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Category Name")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the Category name"
                  defaultValue={singleData && singleData.name}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="uplaod-image-group mt-3">
              <div className="form-group">
                <label className="text-dark" htmlFor="exampleInputSlug">
                  {t("Upload Image")}:<sup className="text-danger">*</sup>
                </label>
                <small className="text-muted">
                  <i className="fa fa-question-circle" />
                  Recommended size (410 x 410px)
                </small>

                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      type="file"
                      onChange={(e) => {
                        handelImage(e);
                      }}
                      name="image"
                      className="custom-file-input"
                      id="image"
                    />
                    <label className="custom-file-label" htmlFor="image">
                      {(inp.image && inp.image.name) || t("Choose File")}
                    </label>
                  </div>
                  <label className="custom-upload" htmlFor="image">
                    {t("Upload")}
                  </label>
                </div>
                {inp.image ? (
                  <img
                    src={URL.createObjectURL(inp.image)}
                    alt="...."
                    width="150"
                    height="150"
                  />
                ) : (
                  singleData &&
                  singleData.image && (
                    <img
                      src={
                        singleData.image instanceof File
                          ? URL.createObjectURL(singleData.image)
                          : singleData.image
                      }
                      alt="...."
                      width="150"
                      height="150"
                    />
                  )
                )}
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <div className="form-group">
                <p>
                  {t("Status")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    singleData
                      ? singleData.status === "0"
                        ? false
                        : true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "status")}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpCategory;
