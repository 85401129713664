import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";
import { RxExternalLink } from "react-icons/rx";
import noData from "../../assits/nodatavector.jpg";
import PaginationList from "../../components/Pagination/Pagination";
import { useState } from "react";
import { getAllHomeData } from "../../store/home";
import { useDispatch } from "react-redux";
import NoDataComponent from "../../uti/NoDataComponent";
import Actions from "../../components/Actions/Actions";
function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

function Row(props) {
  const { row, data, i18n, t } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const getCollabsedTable = (type) => {
    let collabseTable;
    switch (type) {
      case "category":
        collabseTable = renderTable({
          headers: ["Image", "Name", "Status"],
          content: row.content.map((contentRow) => [
            <img
              alt="category-img"
              style={{ width: "10%" }}
              src={contentRow.image || noData}
            />,
            contentRow.name,
            contentRow.status,
          ]),
        });
        break;
      case "slider":
        collabseTable = renderTable({
          headers: ["Image", "Start time", "End time", "Type", "Link"],
          content: row.content.map((contentRow) => [
            <img
              style={{ width: "10%" }}
              src={contentRow.image || noData}
              alt="slider-img"
            />,
            contentRow.start_time,
            contentRow.end_time,
            contentRow.type,
            <a
              className="btn btn-primary btn-sm"
              href={contentRow.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RxExternalLink />
              {t("Banner Link")}
            </a>,
          ]),
        });
        break;
      case "section":
        collabseTable = renderTable({
          headers: ["Image", "Name", "Price", "Link"],
          content: row.content.map((contentRow) => [
            <img
              style={{ width: "10%" }}
              src={contentRow.image || noData}
              alt="section-img"
            />,
            contentRow.name,
            contentRow.price,
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                navigate(`/product/view/${contentRow.id}`);
              }}
            >
              <RxExternalLink />
              {t("Product page")}
            </button>,
          ]),
        });
        break;
      default:
        collabseTable = <p>asd</p>;
    }
    return collabseTable;
  };

  const renderTable = ({ headers, content }) => (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              style={{ display: "flex" }}
            >
              {t("Content")}
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell style={{ width: "15%" }} key={header}>
                      {t(header)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {content.map((rowContent, index) => (
                  <TableRow key={index}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <TableCell key={cellIndex}>{cellContent}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {i18n.language === "en" ? row.title.en : row.title.ar}
        </TableCell>
        <TableCell align="left">{row.type}</TableCell>
        <TableCell align="left">{row.order}</TableCell>
        <TableCell align="right">
          {" "}
          <Actions filter="homePage" params={row} />
        </TableCell>
      </TableRow>
      {getCollabsedTable(row.type)}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function HomeTable({ data }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    dispatch(getAllHomeData({ lang: i18n.language, page: newPage }));
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t("Content")}</TableCell>
            <TableCell align="left">{t("Title")}</TableCell>
            <TableCell align="left">{t("section_type")}</TableCell>
            <TableCell align="left">{t("Order")}</TableCell>
            <TableCell style={{ textAlign: "center" }}>
              {t("Actions")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data?.data?.length === 0 && (
            <NoDataComponent
              colSpan={data?.data?.data?.length}
              text={t("There is no data to show")}
            />
          )}
          {data?.data?.data?.map((item) => (
            <Row key={item.id} row={item} data={data} i18n={i18n} t={t} />
          ))}
        </TableBody>
      </Table>
      <PaginationList
        totalPages={data?.data?.meta?.last_page}
        currentPage={data?.data?.meta?.current_page}
        handlePageChange={handlePageChange}
      />
    </TableContainer>
  );
}
