import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar/Navbar";
import "./adminhome.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllHomeData } from "../../store/home";
import { useTranslation } from "react-i18next";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import HomeTable from "./homeTable";
import ModalMulti from "../../components/ModalMulti/ModalMulti";

function AdminHome() {
  const [t, i18n] = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { loading, error, data } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllHomeData({ lang: i18n.language, page: 1 }));
  }, []);

  let content;
  switch (true) {
    case data:
      content = <HomeTable />;
      break;
    case loading:
      content = (
        <div className="loading">
          <CircularIndeterminate />
        </div>
      );
      break;
    case error:
      content = <ErrorCompo />;
      break;

    default:
      content = <HomeTable data={data} />;
  }

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="home">
        <div className="d-flex justify-content-between align-items-center">
          <h3>{t("Mobile_Home_Page")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                // data={areas}
                filter="homeDynamic"
                type="add"
                title={"Add New"}
              />
            </button>
          </div>
        </div>
        {content}
      </div>
    </div>
  );
}

export default AdminHome;
