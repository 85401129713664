import React, { Fragment, useState } from "react";
import "./ModalMulti.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import FlagIcon from "@mui/icons-material/Flag";
import IosShareIcon from "@mui/icons-material/IosShare";
import PopUpRegions from "../../pages/Folder-Regions/PopUpRegions/PopUpRegions";
import PopUpSellers from "../../pages/Folder-Sellers/PopUpSellers/PopUpSellers";
import PopUpCategory from "../../pages/Folder-Category/PopUpCategory/PopUpCategory";
import PopUpSubCategory from "../../pages/Folder-SubCategory/PopUpSubCategory/PopUpSubCategory";
import PopUpVariant from "../../pages/Folder-Variant/PopUpVariant/PopUpVariant";
import PopUpNotification from "../../pages/FolderNotification/PopUpNotification/PopUpNotification";
import PopUpState from "../../pages/Folder-State/PopUpState/PopUpState";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import PopUpCity from "../../pages/Folder-State/PopUpCity/PopUpCity";
import PopUpCommission from "../../pages/Folder-Commission/PopUpCommission/PopUpCommission";
import PopUpMainDeliveryMethods from "../../pages/Folder-DeliveryMethod/PopUpMainDeliveryMethods/PopUpMainDeliveryMethods";
import PopUpDeliveyAttrebute from "../../pages/Folder-DeliveryAttrebute/PopUpDeliveyAttrebute/PopUpDeliveyAttrebute";
import PopUpBanners from "../../pages/Folder-Banners/PopUpBanners/PopUpBanners";
import PopUpGift from "../../pages/Folder-Gift/PopUpGift/PopUpGift";
import PopUpOrderStatus from "../../pages/Folder-order/PopUpOrderStatus/PopUpOrderStatus";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PopUpAssignOrder from "../../pages/Folder-order/PopUpAssignOrder/PopUpAssignOrder";
import PopUpDeliveryMessage from "../../pages/Folder-DeliveryMessage/PopUDeliveryMessage/PopUpDeliveryMessage";
import PopUpCoupon from "../../pages/Folder-Coupons/PopUpCoupon/PopUpCoupon";
import PopUphomeDynamic from "../../pages/AdminHome/PopUpHome/PopUpHome";
import PopUpProductStatus from "../../pages/Folder-order/MainOrder/PopUpProductStatus/PopUpProductStatus";
import PopUpAssignCoupon from "../../pages/Folder-Coupons/PopUpCoupon/PopUpAssignCoupon";

function ModalMulti({ params: data, filter, type, title }) {

  const [t, i18n] = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  

  const label = { inputProps: { "aria-label": "Size switch demo" } };
  return (
    <div>
      <div
        onClick={(e) => {
          handleOpen();
          e.stopPropagation();
        }}
        className={`d-flex gap-2 align-items-center ${title}`}
      >
        {type === "viwe" ? (
          <VisibilityIcon />
        ) : type === "edit" ? (
          <EditIcon />
        ) : type === "report" ? (
          <FlagIcon />
        ) : type === "share" ? (
          <IosShareIcon />
        ) : type === "addcity" ? (
          <AddLocationAltIcon />
        ) : type === "order-pop" ? (
          ""
        ) : type === "order-assign" ? (
          <AssignmentIndIcon className="order-assign-icon" />
        ) : (
          <AddIcon />
        )}
        {title && t(`${title}`)}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="mmmm">
            {filter === "regions" ? (
              <PopUpRegions
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "Sellers" ? (
              <PopUpSellers
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "Categories" ? (
              <PopUpCategory
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "SubCategories" ? (
              <PopUpSubCategory
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "Variant" ? (
              <PopUpVariant
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "Notification" ? (
              <PopUpNotification
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "states" ? (
              <PopUpState
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "city" ? (
              <PopUpCity
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "Commission" ? (
              <PopUpCommission
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "DeliveryMethod" ? (
              <PopUpMainDeliveryMethods
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "deliveryattrebute" ? (
              <PopUpDeliveyAttrebute
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "Banners" ? (
              <PopUpBanners
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) : filter === "gifts" ? (
              <PopUpGift
                type={type}
                handleClose={handleClose}
                data={data && data.row}
              />
            ) 
            : filter === "statusOrder" ? (
              <PopUpOrderStatus
                type={type}
                handleClose={handleClose}
                data={data && data}
                title={title}
              />
            )
            : filter === "statusProduct" ? (
              <PopUpProductStatus
                type={type}
                handleClose={handleClose}
                data={data && data}
                title={title}
              />
            )
            
            : filter === "assignOrder" ? (
              <PopUpAssignOrder
                type={type}
                handleClose={handleClose}
                data={data && data}
                title={title}
              />
            ) 
            
            : filter === "Coupon" ? (
              <PopUpCoupon
                type={type}
                handleClose={handleClose}
                data={data && data}
                title={title}
              />
            )
            : filter === "assignCoupon" ? (
              <PopUpAssignCoupon
                type={type}
                handleClose={handleClose}
                data={data && data}
                title={title}
              />
            )
            : filter === "homeDynamic" ? (
              <PopUphomeDynamic
                type={type}
                handleClose={handleClose}
                data={data && data}
                title={title}
              />
            )
            
            
            : filter === "deliverymessage" ? (
              <PopUpDeliveryMessage
                type={type}
                handleClose={handleClose}
                data={data && data}
                title={title}
              />
            )
            
            
            
            : (
              ""
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalMulti;
