export const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "#4F8640" : "black",
});

const CustomClearText = () => <>حذف الكل</>;

export const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

export const customSelectTheme = (theme) => ({
  ...theme,
  borderRadius: "5px",
  colors: {
    ...theme.colors,
    primary25: "#4F8640",
    primary50: "#4F8640",
    primary: "#4F8640",
  },
});

export const customStyles = (label) => ({
  option: (provided, state) => ({
    ...provided,

    color: state.data.label === label ? "#4F8640" : "#fff",
    // fontSize:"0.8em",
    // backgroundColor: state.data.label === label ? "var(--sec-color)" : "#000",

    "&:hover": {
      color: state.data.label === label ? "#4F8640" : "#fff",
    },
  }),
});
