import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError } from "../Notification";
import { BASE_URL } from "../apiConfig";
import Earnings from "../pages/Reports/Earnings/Earnings";

export const getAllNumbers = createAsyncThunk(
  "statistics/getAllNumbers",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/reports/statistics`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const getAllOrdersStatus = createAsyncThunk(
  "statistics/getAllOrdersStatus",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/reports/orders`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const getAllCoupounsNumber = createAsyncThunk(
  "statistics/getAllCoupounsNumber",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/reports/coupons`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllCategoriesReport = createAsyncThunk(
  "statistics/getAllCategoriesReport",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/reports/categories?from_date=${arg.from_date}&to_date=${arg.to_date}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const getAllDriversReport = createAsyncThunk(
  "statistics/getAllDriversReport",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/reports/drivers`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// new

export const getAllEarnings = createAsyncThunk(
  "statistics/getAllEarnings",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/reports/earnings?from_date=${arg.from_date}&to_date=${arg.to_date}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const getAllUsedPoint = createAsyncThunk(
  "statistics/getAllUsedPoint",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/reports/used-points?year=${arg.year}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let statisticsSlice = createSlice({
  name: "statistics",
  initialState: {
    loading: false,
    loadingCategories: false,
    loadingeEarnings: false,
    loadingeUsedPoint: false,
    data: false,
    ordersStatus: [],
    categories: [],
    drivers: [],
    earnings: [],
    usedpoint: [],
    couponsNmbers: [],
    error: false,
  },
  reducers: {},
  extraReducers: {
    [getAllNumbers.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = false;
    },
    [getAllNumbers.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllNumbers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    [getAllOrdersStatus.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.ordersStatus = false;
    },
    [getAllOrdersStatus.fulfilled]: (state, action) => {
      state.ordersStatus = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllOrdersStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    [getAllCoupounsNumber.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.couponsNmbers = false;
    },
    [getAllCoupounsNumber.fulfilled]: (state, action) => {
      state.couponsNmbers = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllCoupounsNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    [getAllCategoriesReport.pending]: (state, action) => {
      state.loadingCategories = true;
      state.error = false;
      state.categories = false;
    },
    [getAllCategoriesReport.fulfilled]: (state, action) => {
      state.categories = action.payload;
      state.error = false;
      state.loadingCategories = false;
    },
    [getAllCategoriesReport.rejected]: (state, action) => {
      state.loadingCategories = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    [getAllDriversReport.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.drivers = false;
    },
    [getAllDriversReport.fulfilled]: (state, action) => {
      state.drivers = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllDriversReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    [getAllEarnings.pending]: (state, action) => {
      state.loadingeEarnings = true;
      state.error = false;
      state.earnings = false;
    },
    [getAllEarnings.fulfilled]: (state, action) => {
      state.earnings = action.payload;
      state.error = false;
      state.loadingeEarnings = false;
    },
    [getAllEarnings.rejected]: (state, action) => {
      state.loadingeEarnings = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
    [getAllUsedPoint.pending]: (state, action) => {
      state.loadingeUsedPoint = true;
      state.error = false;
      state.usedpoint = false;
    },
    [getAllUsedPoint.fulfilled]: (state, action) => {
      state.usedpoint = action.payload;
      state.error = false;
      state.loadingeUsedPoint = false;
    },
    [getAllUsedPoint.rejected]: (state, action) => {
      state.loadingeUsedPoint = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default statisticsSlice.reducer;
