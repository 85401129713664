import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { useState } from "react";

export default function BarsChart({
  info,
  setSelectedMonth,
  i18n,
  getAllCategoriesReport,
  dispatch,
  selectedMonth,
  setDate,
  date,
  loadingCategories,
}) {
  const { t } = useTranslation();

  if (loadingCategories) {
    return (
      <div className="loading" style={{ height: "50vh" }}>
        <CircularIndeterminate />
      </div>
    );
  }
  // Extracting data from the info object
  const xAxisData = info?.map((category) => category?.name);
  const seriesData = info?.map((category) => category?.count);
  // console.log("info" , info);
  // console.log("xAxisData" , xAxisData);
  // console.log("seriesData" , seriesData);
  // Generate options for months

  const monthsOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const handleMonthChange = (dateOption) => {
    setDate({
      from_date: date.from_date,
      to_date: date.to_date,
    });
    console.log(date);
    dispatch(
      getAllCategoriesReport({
        lang: i18n.language,
        from_date: date?.from_date,
        to_date: date?.to_date,
        // month: selectedOption.value,
      })
    );
  };
  // if (!info?.categories) {
  // return (
  // <div className="loading" style={{ height: "50vh" }}>
  //   <CircularIndeterminate />
  // </div>
  // );
  // }

  return (
    <div style={{ height: "100vh" }}>
      <>
     
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="text-dark" htmlFor="first_name">
                    {t("From_date")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="date"
                    required
                    type="date"
                    name="from_date"
                    onChange={(e) => {
                      setDate({ ...date, from_date: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="text-dark" htmlFor="first_name">
                    {t("To_date")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="date"
                    required
                    type="date"
                    name="to_date"
                    onChange={(e) => {
                      setDate({ ...date, to_date: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-2 d-flex justify-content-center ">
          <button
            className="btn btn-danger mx-auto"
            onClick={handleMonthChange}
            type="button"
          >
            قم بالبحث
          </button>
        </div>

        <BarChart
          xAxis={[{ scaleType: "band", data: xAxisData && xAxisData }]}
          series={[{ data: seriesData && seriesData }]}
          width={1000}
          height={300}
        />
      </>
    </div>
  );
}
