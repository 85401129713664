import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";

export default function Earnings({ info, i18n, dispatch, loadingeEarnings ,getAllEarnings , setDate3,
  date3, }) {
  const { t } = useTranslation();

  if (loadingeEarnings) {
    return (
      <div className="loading" style={{ height: "50vh" }}>
        <CircularIndeterminate />
      </div>
    );
  }

  const xAxisData = [];
  const seriesData = [];

  for (const year in info?.report) {
    for (const month in info?.report[year]) {
      xAxisData.push(`${month} ${year}`);
      seriesData.push(info.report[year][month]);
    }
  }

  
  const handleMonthChange = (dateOption) => {
    setDate3({
      from_date: date3.from_date,
      to_date: date3.to_date,
    });
    dispatch(
      getAllEarnings({
        lang: i18n.language,
        from_date: date3?.from_date,
        to_date: date3?.to_date,
        // month: selectedOption.value,
      })
    );
  };


  return (
    <div style={{ height: "100vh" }}>
      <>
      <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="text-dark" htmlFor="first_name">
                    {t("From_date")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="date"
                    required
                    type="date"
                    name="from_date"
                    onChange={(e) => {
                      setDate3({ ...date3, from_date: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="text-dark" htmlFor="first_name">
                    {t("To_date")}: <sup className="text-danger">*</sup>
                  </label>
                  <input
                    id="date"
                    required
                    type="date"
                    name="to_date"
                    onChange={(e) => {
                      setDate3({ ...date3, to_date: e.target.value });
                    }}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-2 d-flex justify-content-center ">
          <button
            className="btn btn-danger mx-auto"
            onClick={handleMonthChange}
            type="button"
          >
            قم بالبحث
          </button>
        </div>


     <h1>{t('total')}  : {info?.total}</h1>
      <LineChart
        xAxis={[{ scaleType: "band", data: xAxisData }]}
        series={[{ data: seriesData }]}
        width={1000}
        height={300}
      />
      </>
    </div>
  );
}
