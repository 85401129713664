import React, { useEffect } from "react";
import "./MainOrder.scss";
import { Link } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CollapsibleTable from "../../../components/TableDropDown/CollapsibleTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrdersDelivered,
  resetDataCreate,
  resetDataUpdate,
  resetsingleOrder,
} from "../../../store/orderSlice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { useState } from "react";
import Select from "react-select";
import {
  ClearIndicatorStyles,
  customSelectTheme,
} from "../../../uti/selectStyle";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiFilter } from "react-icons/ci";
import { RxReset } from "react-icons/rx";
import { IoMdDownload } from "react-icons/io";
import { BASE_URL } from "../../../apiConfig";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAllCaptain } from "../../../store/captainSlice";
function MainOrder() {
  const [t, i18n] = useTranslation();
  console.log("i18n", i18n);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  let {
    data: orders,
    loading,
    errorAll,
  } = useSelector((state) => state.orders);

  let {
    data: captains,
    loading: captainsLoading,
    error: captainsError,
  } = useSelector((state) => state.captains);

  console.log("captains", captains);

  let captainsOptions =
    captains &&
    captains?.length > 0 &&
    captains?.map((ca) => {
      return {
        label: ca.first_name + ca.last_name,
        value: ca.id,
      };
    });

  let statusOptions = [
    { label: "Pending", value: 1 },
    { label: "Confirmed", value: 2 },
    { label: "OnDelivery", value: 3 },
    { label: "Deliverd", value: 4 },
    { label: "Cancelled", value: 5 },
    { label: "Returned", value: 6 },
  ];

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllOrdersDelivered({ lang: i18n.language })).then((action) => {
      if (getAllOrdersDelivered.fulfilled.match(action)) {
        setTotalPages(orders.total);
        setCurrentPage(orders.current_page);
      }
    });
    dispatch(getAllCaptain({ lang: i18n.language }));
    dispatch(resetsingleOrder(false));
    dispatch(resetDataCreate(false));
    dispatch(resetDataUpdate(false));
  }, []);

  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedDriver, setSelectedDriver] = useState(0);
  const handleFilter = () => {
    dispatch(
      getAllOrdersDelivered({
        lang: i18n.language,
        page: currentPage,
        status: selectedStatus?.value || 0,
        driver_id: selectedDriver?.value ,
        start_date: startDate,
        end_date: endDate,
      })
    );
  };
  const handleReset = () => {
    setSelectedStatus(null);
    setstartDate(null);
    setendDate(null);
    dispatch(
      getAllOrdersDelivered({
        lang: i18n.language,
        page: currentPage,
      })
    );
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <div className="d-flex gap-1">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="download-tooltip">{t("Download Orders")}</Tooltip>
              }
            >
              <Link
                to={`${BASE_URL}/export-orders-excel?lang=${i18n.language}`}
              >
                <IoMdDownload size={24} />
              </Link>
            </OverlayTrigger>
            <h3>{t("Orders")}</h3>
          </div>

          <div className="orders-filter">
            <Select
              options={statusOptions}
              placeholder={t("Select order status")}
              onChange={(selectedOption) => {
                setSelectedStatus(selectedOption);
              }}
              // value={{value:selectedStatus,label:'asd'}}
              value={selectedStatus}
              components={{ ClearIndicatorStyles }}
              styles={{ clearIndicator: ClearIndicatorStyles }}
              theme={customSelectTheme}
            />
            <Select
              options={captainsOptions}
              placeholder={t("Select Driver")}
              onChange={(selectedOption) => {
                setSelectedDriver(selectedOption);
              }}
              // value={{value:selectedStatus,label:'asd'}}
              value={selectedDriver}
              components={{ ClearIndicatorStyles }}
              styles={{ clearIndicator: ClearIndicatorStyles }}
              theme={customSelectTheme}
            />
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                let formattedDate = moment(date).format("YYYY-MM-DD");
                setstartDate(formattedDate);
              }}
              placeholderText={t("selectStartDate")}
              className="date_inn"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                let formattedDate = moment(date).format("YYYY-MM-DD");
                setendDate(formattedDate);
              }}
              placeholderText={t("selectEndDate")}
              className="date_inn"
            />
            <div className="btn-list">
              <button className="btn" type="button" onClick={handleFilter}>
                <CiFilter />
                {t("Filter")}
              </button>
            </div>
            <div className="btn-list">
              <button className="btn" type="button" onClick={handleReset}>
                <RxReset />
                {t("Reset")}
              </button>
            </div>
          </div>
        </div>
        {errorAll ? (
          <ErrorCompo />
        ) : loading ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <div className="table table-order">
            <CollapsibleTable
              orders={orders}
              dispatch={dispatch}
              getAllOrdersDelivered={getAllOrdersDelivered}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              setTotalPages={setTotalPages}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MainOrder;
