import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import PropTypes from "prop-types";

export default function DriversReport({ info }) {
  // Checking if info is not available yet
  if (!info || !Array.isArray(info) || info.length === 0) {
    return <div>Loading...</div>;
  }

  // Extracting data from the info object
  const xAxisData = info?.map((driver) => driver?.label);
  const seriesData = info?.map((driver) => driver?.value);

  return (
    <BarChart
      xAxis={[{ scaleType: "band", data: xAxisData }]}
      series={[{ data: seriesData }]}
      width={1000}
      height={300}
    />
  );
}

DriversReport.propTypes = {
  info: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  )
};
