import React from "react";
import "./Actions.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalMulti from "../ModalMulti/ModalMulti";
import ModalDelete from "../ModalDelete/ModalDelete";
import { useNavigate } from "react-router-dom";

function Actions({ filter, params }) {
  let navigate = useNavigate();
  let handlepath = (path) => {
    navigate(path);
  };
  return (
    <div className="action-icon">
      {filter === "regions" ? (
        <div className="list-icons">
          {/*view */}
          {/* <div className="icon-view">
            <VisibilityIcon />
          </div> */}
          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="regions" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="regions" params={params} />
          </div>
        </div>
      ) : filter === "Sellers" ? (
        <div className="list-icons">
          {/*view */}
          <div className="icon-view">
            <VisibilityIcon />
          </div>
          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Sellers" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Sellers" params={params} />
          </div>
        </div>
      ) : filter === "Categories" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Categories" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Categories" params={params} />
          </div>
        </div>
      ) : filter === "SubCategories" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="SubCategories" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="SubCategories" params={params} />
          </div>
        </div>
      ) : filter === "Variant" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Variant" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Variant" params={params} />
          </div>
        </div>
      ) : filter === "Product" ? (
        <div className="list-icons">
          {/*view */}
          <div className="icon-view">
            <VisibilityIcon
              onClick={(e) => handlepath(`/product/view/${params.row.id}`)}
            />
          </div>
          {/*edit */}
          <div className="icon-edit">
            <EditIcon
              onClick={(e) => handlepath(`/product/edit/${params.row.id}`)}
            />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Product" params={params} />
          </div>
        </div>
      ) : filter === "Role" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <EditIcon
              onClick={(e) =>
                handlepath(`/roles-permision/edit/${params.row.id}`)
              }
            />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Role" params={params} />
          </div>
        </div>
      ) : filter === "users" ? (
        <div className="list-icons">
          {/*favorit 
          <div className="icon-fav">
            <FavoriteBorderIcon
              onClick={(e) => handlepath(`/user/favourite/${params.row.id}`)}
            />
          </div>*/}
          {/*view */}
          <div className="icon-view">
            <VisibilityIcon
              onClick={(e) => handlepath(`/user/view/${params.row.id}`)}
            />
          </div>
          {/*edit */}
          <div className="icon-edit">
            <EditIcon
              onClick={(e) => handlepath(`/user/edit/${params.row.id}`)}
            />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="users" params={params} />
          </div>
        </div>
      ) : filter === "captins" ? (
        <div className="list-icons">
          {/*view */}
          <div className="icon-view">
            <VisibilityIcon
              onClick={(e) => handlepath(`/captains/view/${params.row.id}`)}
            />
          </div>
          {/*edit */}
          <div className="icon-edit">
            <EditIcon
              onClick={(e) => handlepath(`/captains/edit/${params.row.id}`)}
            />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Captins" params={params} />
          </div>
        </div>
      ) : filter === "Notification" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}

          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Notification" params={params} />
          </div>
        </div>
      ) : filter === "states" ? (
        <div className="list-icons">
          {/*view */}
          {/* <div className="icon-view">
          <VisibilityIcon />
        </div> */}
          {/*add city */}
          {
            <div className="icon-view">
              <ModalMulti params={params} filter="city" type="addcity" />
            </div>
          }
          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="states" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="states" params={params} />
          </div>
        </div>
      ) : filter === "Commission" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Commission" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Commission" params={params} />
          </div>
        </div>
      ) : filter === "DeliveryMethod" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="DeliveryMethod" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="DeliveryMethod" params={params} />
          </div>
        </div>
      ) : filter === "deliveryattrebute" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti
              params={params}
              filter="deliveryattrebute"
              type="edit"
            />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="deliveryattrebute" params={params} />
          </div>
        </div>
      ) : filter === "Banners" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Banners" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Banners" params={params} />
          </div>
        </div>
      ) : filter === "gifts" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="gifts" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="gifts" params={params} />
          </div>
        </div>
      ) : filter === "Coupon" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="Coupon" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="Coupon" params={params} />
          </div>
        </div>
      ) : filter === "deliverymessage" ? (
        <div className="list-icons">
          {/*view */}

          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="deliverymessage" type="edit" />
          </div>
          {/*delte */}
        </div>
      ) : filter === "homePage" ? (
        <div className="list-icons">
          {/*edit */}
          <div className="icon-edit">
            <ModalMulti params={params} filter="homeDynamic" type="edit" />
          </div>
          {/*delte */}
          <div className="icon-delete">
            <ModalDelete filter="homePage" params={params} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Actions;
