import "./PopUpRegions.scss";
import React, { Fragment, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import LocationPicker from "../../../components/LocationPicker/LocationPicker";
import {
  createArea,
  getAllAreas,
  getSingleArea,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataArea,
  updateArea,
} from "../../../store/area";
import { notifyError } from "../../../Notification";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { useNavigate } from "react-router-dom";

function PopUpRegions({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");
  const [t, i18n] = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState(null);

  let dispatch = useDispatch();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.areas);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllAreas({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      dispatch(resetsingleDataArea(false));
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleArea({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };
  let navigate = useNavigate();

  let onsubmitfn = async (e) => {
    e.preventDefault();
    if (type === "add") {
      if (selectedLocation === null) {
        notifyError("Choose Location Please");
        return; // exit the function early
      }
      if (selectedLocation && inp.name) {
        let newdata = {
          ...inp,
          latitude: selectedLocation.lat,
          longitude: selectedLocation.lng,
        };
        try {
          await dispatch(createArea({ data: newdata, lang: i18n.language }));
          navigate("/areas");
        } catch (error) {
          // Handle error
          console.error(error);
        }
      }
    }
    if (type === "edit") {
      let newdata = {
        ...inp,
        _method: "PUT",
        latitude: selectedLocation
          ? selectedLocation.lat
          : singleData && singleData.latitude,
        longitude: selectedLocation
          ? selectedLocation.lng
          : singleData && singleData.longitude,
      };
      try {
        dispatch(
          updateArea({ id: singleData.id, data: newdata, lang: i18n.language })
        );
      }catch(error) {
        console.error(error);
      }
  
    }
  };
  

  return (
    <div>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Area")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Area")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <p className="d-flex justify-content-center btn btn-danger mx-auto " style={{width:"60%"}}>{t('Enter_Add_Lang')}</p>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Area Name")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the Area name"
                  defaultValue={
                    type === "edit" ? singleData && singleData.name : ""
                  }
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <div className="form-group">
                <label>
                  {t("Location")}:<sup className="redstar">*</sup>
                </label>
                {/* Use LocationPicker component here */}
                <div className="m-auto d-flex justify-content-center align-items-center">
                  <LocationPicker
                    onSelectLocation={setSelectedLocation}
                    type={type}
                    singleData={singleData}
                  />
                </div>
              </div>
            </div>

            {/*
                    <div className="col-lg-12 mt-3">
            <div className="form-group">
              <p>
                {t("Status")}:<sup className="redstar">*</sup>
              </p>
              <Switch
                {...label}
                defaultChecked={
                  data ? (data.status === "0" ? false : true) : false
                }
                onChange={(event) => handleSwitchChange(event, "status")}
              />
            </div>
          </div>
    */}

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpRegions;
