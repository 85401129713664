import React, { Fragment, useState } from "react";
import "./CollapsibleTable.scss";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as XLSX from "xlsx";
import ModalMulti from "../ModalMulti/ModalMulti";
import { useTranslation } from "react-i18next";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useNavigate } from "react-router-dom";
import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import TablePagination from "@mui/material/TablePagination";
import { getAllOrdersDelivered } from "../../store/orderSlice";
import PaginationList from "../Pagination/Pagination";
import { FcRating } from "react-icons/fc";
import NoDataComponent from "../../uti/NoDataComponent";
function CollapsibleTable({
  dispatch,
  orders,
  totalPages,
  currentPage,
  setTotalPages,
  setCurrentPage,
}) {
  const [t, i18n] = useTranslation();
  // pagination

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    dispatch(getAllOrdersDelivered({ lang: i18n.language, page: newPage }));
  };

  let navigate = useNavigate();
  let gotToFn = (go) => {
    let { path, id } = go;
    navigate(`${path}/${id}`);
  };
  let newdata = [
    {
      id: 90,
      order_number: "00001",
      user_name: "mayar Al Jabasene",
      Full: 25500,
      captain_name: "",
      ontime: "0",
      status: "pending",
      Payment_Method: "cash",
      history: [
        {
          id: 151,
          product_name: "tomato",
          quantity: 2,
          price: 1200,
          discount: 1200,
          tax: 200,
          commission: 200,
          total: 1200,
          status: "pending",
          description: "<p>qwe</p>",
        },
        {
          id: 153,
          product_name: "Corn",
          quantity: 2,
          price: 700,
          discount: 0,
          tax: 200,
          commission: 200,
          total: 1400,
          status: "pending",
          description: "<p>qwe</p>",
        },
        {
          id: 11,
          product_name: "Kosa",
          quantity: 1,
          price: 2000,
          discount: 0,
          tax: 200,
          commission: 200,
          total: 2000,
          status: "pending",
          description: "<p>qwe</p>",
        },
      ],
    },
    {
      id: 90,
      order_number: "00002",
      user_name: "Hasan Sharf",
      Full: 2342,
      captain_name: "Baraa AlJabasene",
      ontime: "1",
      status: "delivered",
      Payment_Method: "cash",
      history: [
        {
          id: 151,
          product_name: "Apple",
          quantity: 1,
          price: 8500,
          discount: 0,
          tax: 200,
          commission: 200,
          total: 8500,
          status: "delivered",
          description: "<p>qwe</p>",
        },
      ],
    },
  ];

  function Row({ row }) {
    const [open, setOpen] = useState(false);
    const textAlign = i18n.language === "ar" ? "right" : "left";

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ textAlign }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {[
            "id",
            "user_name",
            "total",
            "driver",
            "on_time",
            "payment_method",
            "rate",
            "status",
          ].map((key) => (
            <TableCell key={key} style={{ textAlign }}>
              {key === "driver" ? (
                row.driver || "-"
              ) : key === "status" ? (
                <ModalMulti
                  params={row}
                  filter="statusOrder"
                  type="order-pop"
                  title={row.status}
                />
              ) : key === "rate" ? (
                <div>
                  {row.rate === 0
                    ? "-"
                    : [...Array(row[key])].map((_, index) => (
                        <FcRating key={index} max={5} value={index} />
                      ))}
                </div>
              ) : (
                row[key]
              )}
            </TableCell>
          ))}
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  <div className="d-flex w-100 sskk">
                    <h4>{t("Order Product")}</h4>
                  </div>
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {[
                        "Product Name",
                        "Quantity",
                        "Price",
                        "Discount",
                        "Tax",
                        "Commission",
                        "Total",
                        "Status",
                      ].map((label) => (
                        <TableCell key={label} style={{ textAlign }}>
                          {t(label)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.order_details.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        {[
                          "name",
                          "quantity",
                          "price",
                          "discount",
                          "tax",
                          "commission_value",
                          "total",
                          "status",
                        ].map((key) => (
                          <TableCell key={key} style={{ textAlign }}>
                            {key === "tax" ? (
                              historyRow.product.tax ? (
                                `${historyRow.product.tax} sp`
                              ) : (
                                "0"
                              )
                            ) : key === "price" ? (
                              `${
                                historyRow.product.price
                                  ? `${historyRow.product.price}sp`
                                  : "-"
                              }`
                            ) : key === "name" ? (
                              `${
                                historyRow.product.name
                                  ? `${historyRow.product.name}`
                                  : "-"
                              }`
                            ) : key === "discount" ? (
                              `${
                                historyRow.product.discount
                                  ? `${historyRow.product.discount}sp`
                                  : "-"
                              }`
                            ) : key === "commission_value" ? (
                              `${
                                historyRow.product.commission.commission_value
                                  ? `${historyRow.product.commission.commission_value}sp`
                                  : "-"
                              }`
                            ) : key === "status" ? (
                              <ModalMulti
                                params={historyRow}
                                filter="statusProduct"
                                type="order-pop"
                                title={historyRow.status}
                              />
                            ) : key === "total" ? (
                              historyRow.total ? (
                                historyRow.total
                              ) : (
                                "-"
                              )
                            ) : (
                              historyRow[key]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  //function for export
  function handleExport() {
    const data = newdata.map((row) => [
      row.order_number,
      row.user_name,
      row.Full,
      row.captain_name,
      row.ontime,
      row.Payment_Method,
    ]);
    const historyData =
      newdata.length > 0 &&
      newdata.reduce((acc, row) => {
        row.history.forEach((h) => {
          acc.push([row.name, h.date, h.Fuuull, h.paid, h.Payment_Method]);
        });
        return acc;
      }, []);
    const ws = XLSX.utils.book_new();
    const tableData = [
      ["Name", "Sessions Terminated", "Full Cost", "Been Paid", "Remaining"],
      ...data,
    ];
    const historyHeader = [
      "Name",
      "Date",
      "Full Cost",
      "Been Paid",
      "Payment Method",
    ];
    const historyTableData = [historyHeader, ...historyData];
    const combinedData = [
      ["Data"],
      ...tableData,
      ["History"],
      ...historyTableData,
    ];
    XLSX.utils.book_append_sheet(
      ws,
      XLSX.utils.aoa_to_sheet(combinedData),
      "Combined Data"
    );
    XLSX.writeFile(ws, "table_data.xlsx");
  }
  const cellStyle = {
    textAlign: i18n.language === "ar" ? "right" : "left",
  };
  const tableCells = [
    "",
    "Number",
    "User Name",
    "Full Cost",
    "Captain Name",
    "On Time",
    "Payment Method",
    "Rating",
    "Status",
  ];
  return (
    <>
      {/* {orders?.data?.length > 0 && ( */}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {tableCells.map((label, index) => (
                <TableCell key={index} style={cellStyle}>
                  {t(label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {orders?.data?.length === 0 && (
              <NoDataComponent
                colSpan={tableCells.length}
                text={t("There is no orders to show")}
              />
            )}
            {orders?.data?.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
        <PaginationList
          totalPages={orders.last_page}
          currentPage={orders.current_page}
          handlePageChange={handlePageChange}
        />
      </TableContainer>
      {/* )} */}
    </>
  );
}

export default CollapsibleTable;
