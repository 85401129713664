import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL, REWARD_URL } from "../apiConfig";

export const getAllCoupons = createAsyncThunk(
  "coupons/getAllCoupons",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${REWARD_URL}/coupons`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
          token: "peaklink",
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const getAllCouponsTypes = createAsyncThunk(
  "coupons/getAllCouponsTypes",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${REWARD_URL}/coupons/types`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
          token: "peaklink",
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteCoupons = createAsyncThunk(
  "coupons/deleteCoupons",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    console.log("token", token);
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array

      // Create an array of promises for each deletion request
      const promises = idList.map((id) => {
        const url = `${REWARD_URL}/coupons/delete`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
          token: "peaklink",
        };

        // Construct the request body
        const body = JSON.stringify({ id, ...arg.data });

        // Make the DELETE request using fetch
        return fetch(url, {
          method: "DELETE",
          headers,
          body,
        });
      });

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises);

      // Extract response data from each response if needed
      const responseData = await Promise.all(
        responses.map((response) => response.json())
      );

      return { idList, responses: responseData }; // Return the list of deleted IDs and response data
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let createCoupon = createAsyncThunk(
  "coupons/createCoupon",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      const { data, lang } = arg; // Extract data and lang from arg
      let res = await axios.post(`${REWARD_URL}/coupons/add`, data, {
        // Send data directly
        headers: {
          "Content-Type": "application/json", // Change content type to application/json
          Authorization: `Bearer ${token}`,
          lang: lang,
          token: "peaklink",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export let assignCouponToUser = createAsyncThunk(
  "coupons/assignCouponToUser",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      const { data, lang } = arg; // Extract data and lang from arg
      let res = await axios.post(`${BASE_URL}/reward/coupons/compensate`, data, {
        // Send data directly
        headers: {
          "Content-Type": "application/json", // Change content type to application/json
          Authorization: `Bearer ${token}`,
          lang: lang,
          token: "peaklink",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);



export let updateCoupon = createAsyncThunk(
  "coupons/updateCoupon",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      // const { data, lang } = arg;
      // console.log("arg", arg);

      let res = await axios.put(`${REWARD_URL}/coupons/update`, arg, {
        // Send data directly
        headers: {
          "Content-Type": "application/json", // Change content type to application/json
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
          token: "peaklink",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let couponsSlice = createSlice({
  name: "Coupons",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    types: [],
    coupon: {},

    typesLoading: false,
    typesError: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorDelete: false,
    errorCaptain: false,
    errorSingle: false,
  },
  reducers: {
    setSingleData: (state, action) => {
      state.singleData = action.payload;
    },

    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdate = action.payload;
    },
    resetAllCouponsAction: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    //getAllcategory

    [getAllCoupons.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = false;
    },
    [getAllCoupons.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllCoupons.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
    //getAllcategory

    [getAllCouponsTypes.pending]: (state, action) => {
      state.typesLoading = true;
      state.typesError = false;
      state.types = false;
    },
    [getAllCouponsTypes.fulfilled]: (state, action) => {
      state.types = action.payload;
      state.typesError = false;
      state.typesLoading = false;
    },
    [getAllCouponsTypes.rejected]: (state, action) => {
      state.typesLoading = false;
      state.typesError = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteCoupons

    [deleteCoupons.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteCoupons.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].message);
    },
    [deleteCoupons.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    // create coupon
    [createCoupon.pending]: (state, action) => {
      state.loadingBTN = true;
      state.loading = true;

      state.error = false;
    },
    [createCoupon.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false

      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createCoupon.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    [updateCoupon.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateCoupon.fulfilled]: (state, action) => {
      state.loadingBTN = false;

      state.error = false;
      state.dataUpdate = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateCoupon.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;

      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
    [assignCouponToUser.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [assignCouponToUser.fulfilled]: (state, action) => {
      state.loadingBTN = false;

      state.error = false;
      state.dataUpdate = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [assignCouponToUser.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;

      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },




  },
});

export default couponsSlice.reducer;
export let {
  resetAllCouponsAction,
  resetsingleDatacategory,
  resetDataCreate,
  resetDataUpdate,
  setSingleData,
} = couponsSlice.actions;
